import React, {
  useReducer,
  useContext,
  createContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import { collection, serverTimestamp, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";

import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_CURRENT_STEP,
  SET_STEPS,
  SAVING,
  DEFAULT_REMINDERS,
} from "contexts/shared/constants";
import { SET_DATA, COMPLETE } from "./constants";
import { defaultSetupState, reducer } from "./store";
import useFileUpload from "hooks/useFileUpload";

export const SetupContext = createContext(defaultSetupState);

export const SetupContextProvider = ({ children, steps }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultSetupState,
    steps,
  });

  return (
    <SetupContext.Provider value={[state, dispatch]}>
      {children}
    </SetupContext.Provider>
  );
};

SetupContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  steps: PropTypes.array.isRequired,
};

export const useSetupContext = () => {
  const auth = getAuth();
  const [state, dispatch] = useContext(SetupContext);
  const { currentStep, steps, data, saving, progress } = state;

  const setProgress = useCallback(
    (value) => {
      dispatch({ type: "SAVING", payload: value });
    },
    [dispatch]
  );

  const fileUploader = useFileUpload();
  fileUploader
    .on("start", () => {
      setProgress(1);
    })
    .on("progress", (i) => {
      setProgress(i);
      console.log("Upload progress", i);
    })
    .on("error", (error) => {
      console.log("Upload error", error);
      //setError(error.message);
      setProgress(0);
    })
    .on("complete", () => {
      setProgress(100);
    });

  if (!SetupContext) {
    throw new Error(
      "useSetupContext should be used inside SetupContextProvider"
    );
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const gotoStep = useCallback(
    (stepIndex) => {
      dispatch({ type: SET_CURRENT_STEP, payload: stepIndex });
    },
    [dispatch]
  );

  const setSteps = useCallback(
    (steps) => dispatch({ type: SET_STEPS, payload: { steps } }),
    [dispatch]
  );

  const setData = useCallback(
    (data) => {
      dispatch({ type: SET_DATA, payload: { data } });
    },
    [dispatch]
  );

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return currentStep === totalSteps() - 1;
  };

  const saveFile = (file, path, guestbookId) => {
    return new Promise((resolve, reject) => {
      const filename = `${guestbookId}.${file.name.split(".").pop()}`;
      console.log(filename, path);
      const onSuccess = (url) => resolve({ url, filename, path });
      fileUploader.upload(path, file, filename, onSuccess, reject);
    });
  };

  const finalize = useCallback(async () => {
    const guestbookDoc = doc(collection(db, `guestbook`));
    const { url, path, filename } = await saveFile(
      data.imageFile,
      `guestbook/${guestbookDoc.id}/image`,
      guestbookDoc.id
    );

    dispatch({ type: SAVING, payload: 99 });
    data.image = url;
    data.imageFile = { path, filename };
    console.log(data);
    return setDoc(guestbookDoc, {
      id: guestbookDoc.id,
      uid: auth.currentUser.uid,
      email: auth.currentUser.email,
      ...data,
      created: serverTimestamp(),
      reminders: DEFAULT_REMINDERS,
    }).then(() => {
      return dispatch({
        type: COMPLETE,
        payload: { id: guestbookDoc.id, image: url },
      });
    });
  }, [dispatch, data, auth]);

  return {
    next: isLastStep() ? finalize : incrementCurrentStep,
    previous: decrementCurrentStep,
    goto: gotoStep,
    setSteps,
    setData,
    isLastStep,
    currentStep,
    steps,
    data,
    saving,
    progress,
  };
};

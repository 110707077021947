// Material UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import logo from "assets/images/logos/white-logos/logo-quilted-white.png";

// Router Components
import { Outlet, useNavigate, useLocation } from "react-router-dom";

function DashboardLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const goHome = () => {
    navigate("..");
  };

  return (
    <>
      <AppBar position="static" color="black">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="Dashboard"
            onClick={goHome}
            color="white"
            disabled={location.pathname === "/dashboard"}
          >
            <HomeIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, textAlign: "center", mr: "52px" }}>
            <img src={logo} alt="Quilted" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ pb: "50px" }}>
        <Outlet />
      </Box>
    </>
  );
}

export default DashboardLayout;

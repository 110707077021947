import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import confetti from "assets/images/corner-confetti.png";

const ConfettiBox = styled(Box)(() => ({
  display: "flex",
  flexGrow: 1,
  minHeight: "80vh",
  backgroundImage: `url('${confetti}')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right",
}));

export default ConfettiBox;

import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from "@mui/material/IconButton";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import MovieIcon from "@mui/icons-material/Movie";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CancelIcon from "@mui/icons-material/Cancel";

import { useSnackbar } from "notistack";

import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { doc, collection, setDoc } from "firebase/firestore";

import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";
import responseConverter from "../../firebase/converters/response";
import signatureConverter from "../../firebase/converters/signature";

import GridItem from "components/Grid/GridItem";
import ActionButton from "components/ActionButton";
import ShareBox from "components/ShareBox";
import FileDownloader from "components/FileDownloader";

import SignatureCard from "./components/SignatureCard";
import ResponseCard from "./components/ResponseCard";
import FinalVideoDetail from "./components/FinalVideo/Detail";
import { TextDownloader } from "components/FileDownloader";
import { groupBy } from "utils/array";
import Loading from "pages/guestbook/components/Loading";
import { getResizedImageUrl } from "utils/Storage/getResizedImageUrls";
import { Skeleton } from "@mui/material";

const textFileContentSeperator = `

      -- NEXT --

`;

function TextIcon({ text, ...props }) {
  return (
    <SvgIcon {...props}>
      <text x="8" y="15" fill="inherit">
        {text}
      </text>
    </SvgIcon>
  );
}
function withinTen(props, propName, componentName) {
  componentName = componentName || "ANONYMOUS";

  if (props[propName]) {
    let value = props[propName];
    if (typeof value === "number") {
      return value >= 1 && value <= 10
        ? null
        : new Error(
            propName + " in " + componentName + " is not within 1 to 10"
          );
    }
  }

  // assume all ok
  return null;
}

TextIcon.propTypes = {
  text: withinTen,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default function GuestbookDashboardPage() {
  //const { user } = useAuthContext();
  const { id } = useParams();
  const document = doc(db, "guestbook", id);
  const ref = document.withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(ref);
  const responseCollection = collection(
    db,
    `guestbook/${id}/responses`
  ).withConverter(responseConverter);
  const [responses, responsesLoading, responsesError] =
    useCollectionData(responseCollection);

  const signatureCollection = collection(
    db,
    `guestbook/${id}/signatures`
  ).withConverter(signatureConverter);
  const [signatures, signaturesLoading, signaturesError] =
    useCollectionData(signatureCollection);
  const { enqueueSnackbar: toast, closeSnackbar } = useSnackbar();
  const action = (snackbarId) => (
    <>
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CancelIcon />
      </IconButton>
    </>
  );

  const [tab, setTab] = React.useState(0);
  const [share, setShare] = React.useState(false);
  const [photo, setPhoto] = React.useState();

  React.useEffect(() => {
    if (guestbook) {
      getResizedImageUrl(guestbook.imageFile, "600x600")
        .then((results) => {
          setPhoto(results);
        })
        .catch(() => setPhoto(guestbook.image));
    }
  }, [guestbook]);

  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getSignature = (signatureId) => {
    return signatures.find((signature) => signature.getId() === signatureId);
  };

  const approveFinalVideo = () => {
    setDoc(
      document,
      {
        finalVideo: {
          status: "final",
        },
      },
      { merge: true }
    );
  };

  const [downloadQueueFiles, setDownloadQueueFiles] = React.useState([]);
  const [downloadQueueText, setDownloadQueueText] = React.useState([]);

  const downloadVideo = async (newUrl) => {
    if (newUrl) {
      if (downloadQueueFiles.includes(newUrl)) {
        toast("This file is already downloading.", {
          variant: "error",
          action,
        });
        return false;
      }

      setDownloadQueueFiles((prevFiles) => [...prevFiles, newUrl]);
    }
  };

  const downloadGuestbook = () => {
    const signaturePics = signatures
      .filter((signature) => !!signature.content)
      .filter(
        (signature) => !downloadQueueFiles.includes(signature.content.url)
      )
      .map((signature) => signature.content.url);

    const files = responses
      .filter((response) => ["photo", "video"].includes(response.responseType))
      .filter((response) => !downloadQueueFiles.includes(response.content.url))
      .map((response) => response.content.url);
    setDownloadQueueFiles((prevFiles) => [
      ...prevFiles,
      ...files,
      ...signaturePics,
    ]);

    const text = [];
    const textReponses = responses.filter((response) =>
      ["text"].includes(response.responseType)
    );

    text.push({
      filename: "signatures.txt",
      content: signatures
        .map((signature) => {
          return `${signature.message} (${signature.signature || "unsigned"})`;
        })
        .join(textFileContentSeperator),
    });
    const prompts = groupBy(textReponses, "promptId");
    for (let key in prompts) {
      // eslint-disable-next-line no-prototype-builtins
      if (!prompts.hasOwnProperty(key)) continue;

      const prompt = prompts[key];
      console.log({ prompt });
      const promptResponses = prompt.map((response) => {
        const signature = getSignature(response.signatureId);
        return `${response.content} (${signature.signature || "unsigned"})`;
      });
      text.push({
        filename: `prompt${key}.txt`,
        content: promptResponses.join(textFileContentSeperator),
      });
    }

    setDownloadQueueText(
      text.filter(
        (text) =>
          !downloadQueueText.map((t) => t.filename).includes(text.filename)
      )
    );
  };

  if (loading) return <Loading />;
  if (error) return "error";
  return (
    <React.Fragment>
      <Paper square sx={{ pt: "2rem" }}>
        <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
          <Stack direction="column" spacing={2} mb={2}>
            {loading || !photo ? (
              <Skeleton variant="rectangular" width="100%" height={400} />
            ) : (
              <img
                src={`${photo}`}
                alt={guestbook.title}
                loading="lazy"
                width="100%"
              />
            )}
            <Box>
              <Typography
                align="center"
                variant="h5"
                component="div"
                sx={{ p: 2, pb: 0 }}
              >
                {guestbook.title}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                component="div"
                align="center"
                sx={{ p: 2, pb: 0, textTransform: "capitalize" }}
              >
                {guestbook.finalVideo &&
                guestbook.finalVideo.status !== "requested" ? (
                  <>
                    Final Video Received:{" "}
                    {guestbook.finalVideo.requested.format("ll")}
                  </>
                ) : (
                  <>
                    Package Level: <strong>{guestbook.tier}</strong>
                  </>
                )}
              </Typography>
            </Box>
            {guestbook.finalVideo && (
              <FinalVideoDetail
                {...guestbook.finalVideo}
                onApproveFinal={approveFinalVideo}
              />
            )}
            {!guestbook.finalVideo && (
              <Grid container spacing={2}>
                <GridItem item xs={3}>
                  <ActionButton
                    label="Invites &amp; Sharing"
                    icon={<GroupAddIcon color="white" />}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      navigate("invites");
                    }}
                  />
                </GridItem>
                <GridItem item xs={3}>
                  <ActionButton
                    label="Download Responses"
                    icon={<DownloadIcon color="white" />}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={downloadGuestbook}
                  />
                </GridItem>
                <GridItem item xs={3}>
                  <ActionButton
                    label="Order Final Movie"
                    icon={<MovieIcon color="white" />}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      navigate("start-final");
                    }}
                  />
                </GridItem>
                <GridItem item xs={3}>
                  <ActionButton
                    label="Settings"
                    icon={<SettingsIcon color="white" />}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      navigate("settings");
                    }}
                  />
                </GridItem>
              </Grid>
            )}
            {guestbook.finalVideo && (
              <Grid container spacing={2}>
                {guestbook.finalVideo.status !== "review" && (
                  <GridItem item xs>
                    <ActionButton
                      label="Download Guestbook"
                      icon={<DownloadIcon color="white" />}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={downloadGuestbook}
                    />
                  </GridItem>
                )}
                {guestbook.finalVideo.status === "final" && (
                  <GridItem item xs>
                    <ActionButton
                      label="Download Final Video"
                      icon={<MovieIcon color="white" />}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        console.log("Download final video");
                        downloadVideo(guestbook.finalVideo.video);
                      }}
                    />
                  </GridItem>
                )}
              </Grid>
            )}
            <FileDownloader
              downloadQueueFiles={downloadQueueFiles}
              setDownloadQueueFiles={setDownloadQueueFiles}
            />
            <TextDownloader
              downloadQueueText={downloadQueueText}
              setDownloadQueueText={setDownloadQueueText}
            />
            <Box py={4}>
              <Tabs onChange={handleTabChange} value={tab}>
                <Tab
                  icon={<MenuBookIcon fontSize="small" />}
                  iconPosition="bottom"
                  label="Signatures"
                />
                <Tab
                  icon={<TextIcon text="1" fontSize="small" />}
                  iconPosition="bottom"
                  label="Prompt"
                />
                <Tab
                  icon={<TextIcon text="2" fontSize="small" />}
                  iconPosition="bottom"
                  label="Prompt"
                />
                <Tab
                  icon={<TextIcon text="3" fontSize="small" />}
                  iconPosition="bottom"
                  label="Prompt"
                />
              </Tabs>
            </Box>
          </Stack>
          <TabPanel value={tab} index={0}>
            <Typography variant="body2" gutterBottom align="center">
              Signatures
            </Typography>
          </TabPanel>
          {guestbook.prompts.map((prompt, index) => (
            <TabPanel value={tab} index={index + 1} key={prompt.id}>
              <Typography align="center" gutterBottom mb={4}>
                {prompt.text}
              </Typography>
              <Typography variant="body2" gutterBottom align="center">
                Responses
              </Typography>
            </TabPanel>
          ))}
        </Box>
      </Paper>
      <Box sx={{ maxWidth: 400, mx: "auto!important", p: 4 }}>
        <TabPanel value={tab} index={0}>
          <Stack
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            {signaturesLoading
              ? "loading"
              : signaturesError
              ? "error"
              : signatures &&
                signatures.map((signature) => (
                  <SignatureCard signature={signature} key={signature.uid} />
                ))}
          </Stack>
        </TabPanel>

        {guestbook.prompts.map((prompt, index) => (
          <TabPanel value={tab} index={index + 1} key={prompt.id}>
            <Stack
              spacing={2}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              {responsesLoading
                ? "loading"
                : responsesError
                ? "error"
                : responses &&
                  responses
                    .filter((response) => response.promptId === prompt.id)
                    .map((response, index) => (
                      <ResponseCard
                        signature={getSignature(response.signatureId)}
                        response={response}
                        key={index}
                      />
                    ))}
            </Stack>
          </TabPanel>
        ))}
        <Dialog
          open={share}
          onClose={() => setShare(false)}
          aria-labelledby={`alert-dialog-title-${guestbook.slug}`}
          aria-describedby={`alert-dialog-description-${guestbook.slug}`}
        >
          <DialogTitle id={`alert-dialog-title-${guestbook.slug}`}>
            Share Your Digital Guestbook
          </DialogTitle>
          <DialogContent>
            <ShareBox slug={guestbook.slug} />
          </DialogContent>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

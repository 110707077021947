import * as React from "react";
import update from "immutability-helper";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { useInvitesContext } from "contexts/guestbook/invites/InvitesContextProvider";

import GridItem from "components/Grid/GridItem";
import ActionButton from "components/ActionButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKSnackbar from "components/MKSnackbar";
import CloudSpongeWidget from "components/ContactPicker/CloudSpongeWidget";

import NewInvitesTable from "./NewInvitesTable";
import AddContactForm from "./AddContactForm";
import useLocalStorage from "hooks/useLocalStorage";

const sampleMessage =
  "We’re getting married! We would love for you to take a few moments to sign our Digital Wedding Guestbook. Share your wishes and stories and they’ll be woven into an awesome video keepsake.";

export default function SendInvitations() {
  //const { user } = useAuthContext();
  const {
    loading,
    //guestbookId,
    settings,
    //invitations,
    //error,
    //saving,
    //progress,
    save,
  } = useInvitesContext();

  const [open, setOpen] = useLocalStorage("tip-2-is-open", true);

  const [show, setShow] = React.useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [message, setMessage] = React.useState(
    settings?.message || sampleMessage
  );
  const [signature, setSignature] = React.useState(settings?.signature || "");
  const [newInvitations, setNewInvitations] = React.useState([]);

  React.useEffect(() => {
    if (!loading && settings?.message) {
      setMessage(settings.message);
    }
  }, [loading, settings?.message]);

  React.useEffect(() => {
    if (!loading && settings?.signature) {
      setSignature(settings.signature);
    }
  }, [loading, settings?.signature]);

  const onSave = () => {
    save({ message, signature, invitations: newInvitations }).then(() => {
      setNewInvitations([]);
    });
  };

  const sendInvitation = (invite) => {
    return save({ message, signature, invitations: [invite] });
  };

  const addNewInvitations = (data) => {
    setNewInvitations(
      update(newInvitations, {
        $push: [...data],
      })
    );
    setShow(true);
  };

  if (loading) return;
  return (
    <React.Fragment>
      <MKBox component="section" py={4} maxWidth="400px">
        <Stack direction="column" spacing={2}>
          <MKTypography>What would you like your invites to say?</MKTypography>
          <MKInput
            label="Invite Language"
            multiline
            fullWidth
            rows={5}
            placeholder={sampleMessage}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <MKInput
            label="Signature"
            fullWidth
            placeholder="e.g. With Love, Jake and Ginny"
            value={signature || ""}
            onChange={(e) => {
              setSignature(e.target.value);
            }}
          />
          <MKTypography>Who would you like to invite?</MKTypography>
          <Collapse in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Invite friends & family near and far through email or text
              message. They’ll receive a link to your custom landing page to
              sign your guestbook and add their responses. We recommend inviting{" "}
              <strong>at least 30 people</strong> for the best results!
            </Alert>
          </Collapse>

          <CloudSpongeWidget
            onImport={(data) => addNewInvitations(data)}
            limit={200}
            cloudspongeKey="3u8r6LpjVRMeN1E6pG1-XA"
          >
            {({ launchCloudspongeHandler }) => (
              <Grid container>
                <GridItem item xs={2}>
                  &nbsp;
                </GridItem>
                <GridItem item xs={4}>
                  <ActionButton
                    label="Import from Address Book"
                    icon={<ImportContactsIcon color="info" />}
                    variant="contained"
                    size="oversized"
                    color="light"
                    onClick={() => {
                      launchCloudspongeHandler();
                    }}
                  />
                </GridItem>

                <GridItem item xs={4}>
                  <ActionButton
                    label="Upload File (csv)"
                    icon={<UploadFileIcon color="info" />}
                    variant="contained"
                    size="oversized"
                    color="light"
                    onClick={() => {
                      launchCloudspongeHandler("csv");
                    }}
                  />
                </GridItem>
                <GridItem item xs={2}>
                  &nbsp;
                </GridItem>
              </Grid>
            )}
          </CloudSpongeWidget>
          <AddContactForm onAddContact={sendInvitation} />
          <Grow
            in={!!(newInvitations && newInvitations.length)}
            easing={{
              enter: "cubic-bezier(0, 1.5, .8, 1)",
              exit: "cubic-bezier(0, 1.5, .8, 1)",
            }}
          >
            <MKBox>
              <NewInvitesTable
                data={newInvitations}
                onDelete={(index) => {
                  setNewInvitations(
                    update(newInvitations, { $splice: [[index, 1]] })
                  );
                }}
              />
              <MKBox sx={{ textAlign: "center" }} pt={2}>
                <MKButton variant="gradient" color="info" onClick={onSave}>
                  Send Invites
                </MKButton>
              </MKBox>
            </MKBox>
          </Grow>
        </Stack>
        <MKSnackbar
          color="info"
          icon="notifications"
          title="Contact(s) Added"
          content="Contact(s) added and are waiting to be sent."
          open={show}
          close={toggleSnackbar}
        />
      </MKBox>
    </React.Fragment>
  );
}

/* eslint-disable no-unused-vars */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InfoIcon from "@mui/icons-material/Info";
import MKTypography from "components/MKTypography";
import Tooltip from "@mui/material/Tooltip";
import MKButton from "components/MKButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";

// Analytics
import analytics from "../../../analytics";

const Item = styled(Box)(() => ({
  textAlign: "center",
}));

export default function RemindMe() {
  const navigate = useNavigate();
  const { signed, remind } = useGuestbookContext();
  const [useEmail, setUseEmail] = React.useState(true);
  const [contact, setContact] = React.useState("");

  const { sendReminders } = signed;

  const handleChange = (event, contactMethod) => {
    analytics.track("guestbook:finish_later:contact_method_changed", {
      contactMethod,
    });
    setUseEmail(contactMethod === "email");
  };

  const handleSubmit = () => {
    analytics.track("guestbook:finish_later:save");
    remind({ contact, method: useEmail ? "email" : "phone" }).then(() => {
      navigate("finish-later");
    });
  };
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(true);

  const handleClickOpen = () => {
    analytics.track("guestbook:finish_later_click");
    setOpen(true);
  };

  const handleClose = () => {
    analytics.track("guestbook:finish_later_cancel_click");
    setOpen(false);
  };

  return sendReminders ? (
    <Collapse in={show}>
      <Alert
        severity="success"
        onClose={() => {
          setShow(false);
        }}
      >
        Success! We&apos;ll send you reminders.
      </Alert>
    </Collapse>
  ) : (
    <>
      <Collapse in={show}>
        <Alert
          severity="info"
          onClose={() => {
            setShow(false);
          }}
        >
          Need more time to add your responses? No problem! Leave your contact
          info by clicking the link below and we’ll remind you to participate
          before our due date.
        </Alert>
      </Collapse>
      <MKButton variant="text" color="info" onClick={handleClickOpen}>
        Finish later
      </MKButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Finish Later</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Busy now? No problem! Leave your contact info here and we&apos;ll
            remind you to share any videos or photos with us later.
          </DialogContentText>
          <Stack spacing={2} mt={2}>
            <Item px={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="contact">
                  {useEmail ? "Email" : "Phone"}
                </InputLabel>
                <OutlinedInput
                  id="contact"
                  label={useEmail ? "Email" : "Phone"}
                  type={useEmail ? "email" : "phone"}
                  onChange={(e) => setContact(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <ToggleButtonGroup
                        value={useEmail ? "email" : "phone"}
                        exclusive
                        onChange={handleChange}
                      >
                        <ToggleButton value="email" aria-label="email">
                          <EmailIcon />
                        </ToggleButton>
                        <ToggleButton value="phone" aria-label="phone">
                          <PhoneIphoneIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Item>
          </Stack>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton color="dark" onClick={handleSubmit}>
            Submit
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

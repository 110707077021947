import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  onAuthStateChanged,
  signOut,
  sendSignInLinkToEmail,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import { LinearProgress } from "@mui/material";
import buildEmailActionCodeSettings from "utils/Authentication/buildEmailActionCodeSettings";
const fnNotDefined = async () => {
  const defined = null;
  if (defined) return {};
  else throw Error("Method not defined.");
};

const userAuthContext = createContext({
  user: undefined,
  logIn: fnNotDefined,
  signUp: fnNotDefined,
  logOut: fnNotDefined,
  signInAnon: fnNotDefined,
});

const { Provider } = userAuthContext;

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(undefined);

  function logInWithEmail(email) {
    return fetchSignInMethodsForEmail(auth, email)
      .then((signInMethods) => {
        let allowed = false;
        if (
          signInMethods.indexOf(
            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          ) != -1
        ) {
          allowed = true;
        }
        if (
          signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !=
          -1
        ) {
          allowed = true;
        }
        return allowed;
      })
      .then((allowed) => {
        if (!allowed) throw Error("User not found or allowed.");
        const actionCodeSettings = buildEmailActionCodeSettings("sign-in");
        return sendSignInLinkToEmail(auth, email, actionCodeSettings).then(
          () => {
            window.localStorage.setItem("emailForSignIn", email);
          }
        );
      });
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  const signInAnon = useCallback(() => {
    if (!user) signInAnonymously(auth);
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Provider
      value={{ user, logIn, logInWithEmail, signUp, logOut, signInAnon }}
    >
      {user === undefined && <LinearProgress />}
      {user !== undefined && children}
    </Provider>
  );
}

// Typechecking props for the BasicLayout
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuthContext() {
  return useContext(userAuthContext);
}

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SignatureAvatar from "./SignatureAvatar";

export default function SignatureCard({ signature }) {
  const [isOpen, setOpen] = React.useState();
  return (
    <Card sx={{ width: "100%", maxWidth: 345, mx: "auto" }}>
      <CardHeader
        avatar={<SignatureAvatar signature={signature} initialsOnly />}
        title={signature.signature}
      />
      {signature.content && (
        <CardMedia
          component="img"
          alt="Selected File Preview"
          image={signature.content.url}
        />
      )}
      <CardContent>
        <Typography variant="body2" noWrap={!isOpen}>
          {signature.message}
        </Typography>
      </CardContent>
      {signature.message.length > 36 && (
        <CardActions>
          <Button
            size="small"
            onClick={() => setOpen((open) => !open)}
            sx={{ mx: "auto" }}
          >
            {isOpen ? "Less" : "More"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

SignatureCard.propTypes = {
  signature: PropTypes.object.isRequired,
};

import * as React from "react";
import { useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import MKSnackbar from "components/MKSnackbar";

import SignGuestbook from "./components/SignGuestbook";
import Prompts from "./components/Prompts";
import Loading from "./components/Loading";
import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";

export default function Guestbook() {
  const { loading, signed } = useGuestbookContext();
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ maxWidth: "400px", mx: "auto!important" }}>
        {loading && <Loading />}
        {!loading && (
          <>
            {!signed && <SignGuestbook onSigned={toggleSnackbar} />}
            {!!signed && (
              <>
                <Prompts />
                <MKSnackbar
                  color="success"
                  icon="check_circle"
                  title="Thanks for signing our digital guestbook!"
                  content={
                    <>
                      Now we ask you to share a few thoughts or memories to add
                      to our digital keepsake. <br />
                      Don&apos;t worry if now is not the best time - we&apos;ll
                      remind you in coming days.
                    </>
                  }
                  open={show}
                  close={toggleSnackbar}
                />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

import * as React from "react";
//import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";
import Loading from "pages/guestbook/components/Loading";

import SilverUpgrade from "./components/FinalVideo/SilverUpgrade";
import GoldStartFinal from "./components/FinalVideo/GoldStartFinal";
import DiamondStartFinal from "./components/FinalVideo/DiamondStartFinal";
import { FinalVideoContextProvider } from "contexts/guestbook/finalVideo/Context";

export default function StartFinalPage() {
  const { id } = useParams();
  const ref = doc(db, "guestbook", id).withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(ref);

  if (loading && !guestbook) return <Loading />;
  if (error) return <Alert severity="error">Error</Alert>;
  return (
    <React.Fragment>
      <FinalVideoContextProvider guestbookId={id}>
        <Paper square sx={{ pt: "2rem" }}>
          <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
            {guestbook.tier === "silver" ? (
              <SilverUpgrade />
            ) : guestbook.tier === "gold" ? (
              <GoldStartFinal />
            ) : (
              <DiamondStartFinal />
            )}
          </Box>
        </Paper>
      </FinalVideoContextProvider>
    </React.Fragment>
  );
}

//import update from "immutability-helper";
import { SAVING } from "contexts/shared/constants";
import {
  SET_DATA,
  COMPLETE,
  FILE_UPLOADING,
  FILE_UPLOADED,
  SET_FILES,
} from "./constants";

export const defaultFinalVideoState = {
  guestbookId: undefined,
  data: {},
  files: undefined,
  saving: false,
  progress: undefined,
};

export const reducer = (state = defaultFinalVideoState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DATA: {
      return {
        ...state,
        data: payload,
      };
    }
    case SET_FILES: {
      return {
        ...state,
        files: payload,
        uploads: Array.from(payload, ({ pathRoot, name }) => ({
          uploading: false,
          uploaded: false,
          pathRoot,
          name,
        })),
      };
    }
    case COMPLETE: {
      return { ...state, complete: true, saving: false };
    }
    case FILE_UPLOADING: {
      const { uploads } = state;
      const { index } = payload;
      uploads[index].uploading = true;
      return { ...state, uploads: [...uploads] };
    }
    case FILE_UPLOADED: {
      const { uploads } = state;
      const { index, url } = payload;
      uploads[index].uploading = false;
      uploads[index].uploaded = true;
      uploads[index].url = url;
      return { ...state, uploads: [...uploads] };
    }
    case SAVING: {
      return {
        ...state,
        saving: !!(payload > 0 && payload < 100),
        progress: payload,
      };
    }

    default:
      return state;
  }
};

/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

export default function PricingCard({ variant, current, ...props }) {
  const colors = {
    silver: "light",
    gold: "dark",
    diamond: "diamond",
  };
  return (
    <SimplePricingCard
      {...props}
      variant="gradient"
      color={colors[variant]}
      elevation={current ? 10 : 1}
    />
  );
}
// Setting default props for the SimplePricingCard
PricingCard.defaultProps = {
  variant: "silver",
  current: false,
};

PricingCard.propTypes = {
  variant: PropTypes.oneOf(["silver", "gold", "diamond"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
  }),
  specifications: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  current: PropTypes.bool,
};

function SimplePricingCard({
  variant,
  color,
  title,
  description,
  price,
  specifications,
  action,
  elevation,
}) {
  let titleColor = "white";
  let textColor = "white";
  let priceColor = "white";
  let buttonColor = "white";

  if (variant === "contained") {
    titleColor = color;
    textColor = color;
    priceColor = color;
    buttonColor = color;
  } else if (
    variant === "gradient" &&
    (color === "light" || color === "diamond")
  ) {
    titleColor = "dark";
    priceColor = "dark";
    buttonColor = "dark";
    textColor = "dark";
  } else if (variant === "gradient" && color === "dark") {
    titleColor = "gold";
  }

  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MKBox key={label} display="flex" alignItems="center" p={1}>
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MKTypography
          variant="h3"
          color={color === "white" ? "text" : textColor}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{includes ? "check_circle" : "radio_button_unchecked"}</Icon>
        </MKTypography>
      </MKBox>
      <MKTypography
        variant="body2"
        color={color === "white" ? "text" : textColor}
        fontWeight="regular"
      >
        {label}
      </MKTypography>
    </MKBox>
  ));

  return (
    <Card
      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
      elevation={elevation}
    >
      <MKBox
        variant={variant}
        bgColor={variant === "gradient" ? color : "white"}
        height="100%"
      >
        <MKBox p={3} lineHeight={1}>
          <MKTypography
            variant="h3"
            fontWeight="bold"
            color={titleColor}
            mb={0.5}
          >
            {title}
          </MKTypography>
          <MKTypography
            variant="button"
            color={
              variant === "contained" ||
              (variant === "gradient" && color === "light")
                ? "text"
                : textColor
            }
            mb={2}
          >
            {description}
          </MKTypography>
        </MKBox>
        <Divider
          light={variant === "gradient" && color === "dark"}
          variant="middle"
          sx={{ my: 0 }}
        />
        <MKBox p={3}>{renderSpecifications}</MKBox>
        <MKBox p={3} lineHeight={1}>
          {action &&
            (action.type === "internal" ? (
              <MKBox mt={3}>
                <MKButton
                  component={Link}
                  to={action.route}
                  variant={variant === "gradient" ? "contained" : "gradient"}
                  size="small"
                  color={buttonColor}
                  fullWidth
                >
                  {action.label}
                </MKButton>
              </MKBox>
            ) : (
              <MKBox mt={3}>
                <MKButton
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant={variant === "gradient" ? "contained" : "gradient"}
                  size="small"
                  color={buttonColor}
                  fullWidth
                >
                  {action.label}
                </MKButton>
              </MKBox>
            ))}
          {price && (
            <MKTypography
              variant="h6"
              color={priceColor}
              mt={2}
              mb={1}
              sx={{ textAlign: "center" }}
            >
              {price.value}
              {price.type && (
                <MKTypography
                  display="inline"
                  component="small"
                  variant="h6"
                  color={
                    variant === "contained" ||
                    (variant === "gradient" && color === "light")
                      ? "secondary"
                      : "white"
                  }
                  sx={{
                    fontFamily: ({ typography: { h1 } }) => h1.fontFamily,
                  }}
                >
                  &nbsp; / {price.type}
                </MKTypography>
              )}
            </MKTypography>
          )}
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Setting default props for the SimplePricingCard
SimplePricingCard.defaultProps = {
  color: "dark",
  variant: "contained",
  elevation: 1,
};

// Typechecking props for the SimplePricingCard
SimplePricingCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "diamond",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
  }),
  specifications: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  elevation: PropTypes.number,
};

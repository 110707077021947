import React from "react";
import PropTypes from "prop-types";

// Simple React Component that manages adding the cloudsponge object to the page
//  and initializing it with the options that were passed through.
// props:
//  * cloudspongeKey (required): your widget key from your CloudSponge account https://app.cloudsponge.com/app
//  * options (optional): any javascript options you wish to pass to configure the cloudsponge object
//
// For example, you can use the component like so:
// import CloudSpongeWidget from './CloudSpongeWidget'
// // react boilerplate ...
// <CloudSpongeWidget
//     cloudspongeKey="localhost-only"
//     options={{
//         selectionLimit: this.availableNewUserSlots.bind(this),
//         afterSubmitContacts: this.addUsers.bind(this),
//     }}
// >
// {({launchCloudspongeHandler}) => (
//     <a className="cloudsponge-launch" data-cloudsponge-source="gmail">
//         Add your Gmail Contacts
//     </a>
// )}
// </CloudSpongeWidget>
const kv = (name) => [name, name];
const valid_sources = new Map([
  kv("gmail"),
  kv("yahoo"),
  kv("windowslive"),
  kv("outlookcom"),
  kv("office365"),
  kv("csv"),
  kv("linkedin"),
  kv("aol"),
  kv("icloud"),
  kv("outlook"),
  kv("addressbook"),
  kv("mail_ru"),
  kv("uol"),
  kv("bol"),
  kv("terra"),
  kv("rediff"),
  kv("mail126"),
  kv("mail163"),
  kv("mail_yeah_net"),
  kv("gmx"),
  kv("qip_ru"),
  kv("sapo"),
  kv("mailcom"),
  kv("yandex_ru"),
]);
const sourceIsValid = (source) => {
  return valid_sources.has(source);
};

class CloudSpongeWidget extends React.Component {
  static defaultProps = {
    limit: 50,
    cloudspongeKey: "localhost-only",
  };

  afterSubmitContacts(contacts, source, owner) {
    console.log({ owner, source, contacts });
    const imported = contacts.map((contact) => {
      const name = contact.fullName();
      const email = contact.selectedEmail();
      const phone = contact.selectedPhone();
      //const raw = JSON.stringify({...contact });
      return { name, email, phone, source };
    });
    this.props.onImport(imported);
  }

  launchCloudspongeHandler(source) {
    if (sourceIsValid(source)) {
      console.log("Launching with a source", { source });
      window.cloudsponge.launch(source);
    } else {
      console.log("Launching without a source");
      window.cloudsponge.launch();
    }
  }

  // adds an async script tag to the page and invokes a callback when the script has loaded
  addJavascript(src, id, callback) {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      callback && callback();
      return;
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = 1;
    if (id) {
      scriptTag.id = id;
    }

    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (
            scriptTag.readyState === "loaded" ||
            scriptTag.readyState === "complete"
          ) {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null;
            callback();
          }
        };
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback();
        };
      }
    }

    // assign the src attribute
    scriptTag.src = src;
    // add the script to the page
    document.body.appendChild(scriptTag);
  }

  componentDidMount() {
    this.addJavascript(
      `https://api.cloudsponge.com/widget/${this.props.cloudspongeKey}.js`,
      "__cloudsponge_widget_script",
      () => {
        const options = {
          theme: "frost",
          selectAccount: true,
          selectionLimit: this.props.limit,
          afterSubmitContacts: this.afterSubmitContacts.bind(this),
          displayContactsColumns: ["phone"],
          filter: function (contact) {
            return contact.email.length || contact.phone.length;
          },
          sources: [
            "gmail",
            "yahoo",
            "outlookcom",
            "office365",
            "aol",
            "icloud",
          ],
          ...(this.props.options || {}),
        };
        if (window.cloudsponge) {
          window.cloudsponge.init(options);
        }
      }
    );
  }

  render() {
    return (
      this.props.children &&
      this.props.children({
        launchCloudspongeHandler: this.launchCloudspongeHandler,
      })
    );
  }
}

CloudSpongeWidget.propTypes = {
  cloudspongeKey: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  onImport: PropTypes.func.isRequired,
  options: PropTypes.object,
  children: PropTypes.node,
};

export default CloudSpongeWidget;

import * as React from "react";
import { useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MKButton from "components/MKButton";

import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";
import ResponseButton from "./ResponseButton";
import TextResponse from "./responses/TextResponse";
import PhotoResponse from "./responses/PhotoResponse";
import VideoResponse from "./responses/VideoResponse";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import RemindMe from "./RemindMe";
// Analytics
import analytics from "../../../analytics";

const GridItem = styled(Grid)(() => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

export default function Prompts() {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    loading,
    saving,
    guestbook,
    respond,
    remove,
    responses,
    maxSteps,
    currentStep,
    next,
    previous,
    goto,
  } = useGuestbookContext();

  const [responding, setResponding] = React.useState(false);
  const [responseType, setResponseType] = React.useState("text");
  const [response, setResponse] = React.useState();

  React.useEffect(() => {
    setResponse(getResponse(currentStep));
  }, [responses, currentStep]);

  const getResponse = React.useCallback(
    (step) => {
      if (step >= guestbook.prompts.length) return null;
      const promptId = guestbook && guestbook.prompts[step].id;
      return (
        responses && responses.find((response) => response.promptId == promptId)
      );
    },
    [responses, guestbook]
  );

  const handleNext = () => {
    analytics.track("guestbook:prompts:next_click", {
      responding,
      responseType,
      response: !!response,
    });
    setResponding(false);
    next();
  };

  const handleStep = (step) => {
    analytics.track("guestbook:prompts:step_click", {
      responding: !!responding,
      responseType,
      response: !!response,
      step,
    });
    setResponding(false);
    goto(step);
  };

  const handleBack = () => {
    analytics.track("guestbook:prompts:back_click", {
      responding: !!responding,
      responseType,
      response: !!response,
    });
    setResponding(false);
    previous();
  };

  const handleResponse = (response) => {
    const prompt = guestbook.prompts[currentStep];
    analytics.track("guestbook:prompts:save_click", {
      responding: !!responding,
      responseType,
      response: !!response,
      prompt: prompt.text,
      currentStep,
    });
    const promptId = prompt.id;
    respond &&
      respond({ ...response, promptId }).then(() => setResponding(false));
  };

  const handleFileChange = (e, responseType) => {
    analytics.track("guestbook:prompts:respond_media", {
      responseType,
      currentStep,
    });
    e.preventDefault();
    if (!e.target.files || e.target.files.length <= 0) return;
    let file = e.target.files[0];
    setResponseType(responseType);
    setResponding(file);
  };

  const stepComplete = useCallback(
    (step) => {
      const found = getResponse(step);
      return !!found;
    },
    [responses]
  );

  const handleFinishClick = () => {
    analytics.track("guestbook:prompts:finish_click", {
      currentStep,
    });
    navigate("finished");
  };

  if (loading) return "Loading...";
  return (
    <Box p={4}>
      <Stack spacing={4}>
        <Stepper nonLinear activeStep={currentStep} alternativeLabel>
          {guestbook.prompts.map(({ id }, index) => (
            <Step key={id} completed={stepComplete(index)}>
              <StepButton
                color="inherit"
                onClick={() => handleStep(index)}
                disabled={saving}
              >
                Prompt {index + 1}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Typography>{guestbook.prompts[currentStep].text}</Typography>
        {!responding && !response && (
          <Box sx={{ maxWidth: 400 }} mx="auto">
            <Grid container spacing={2}>
              <GridItem item xs={4}>
                <ResponseButton
                  label="Text"
                  icon={<InsertCommentIcon color="white" />}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    analytics.track("guestbook:prompts:respond_text_click", {
                      currentStep,
                    });
                    setResponding(true);
                    setResponseType("text");
                  }}
                />
              </GridItem>
              <GridItem item xs={4}>
                <ResponseButton
                  label="Video"
                  icon={<VideoCameraFrontIcon color="white" />}
                  variant="contained"
                  size="large"
                  color="primary"
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    accept="video/*"
                    onChange={(e) => {
                      handleFileChange(e, "video");
                    }}
                  />
                </ResponseButton>
              </GridItem>
              <GridItem item xs={4}>
                <ResponseButton
                  label="Photo"
                  icon={<AddAPhotoIcon color="white" />}
                  variant="contained"
                  size="large"
                  color="primary"
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => {
                      handleFileChange(e, "photo");
                    }}
                  />
                </ResponseButton>
              </GridItem>
            </Grid>
          </Box>
        )}
        {response &&
          (response.responseType === "text" ? (
            <TextResponse
              key={response.id}
              onCancel={() => remove(response)}
              previousValue={response.content}
            />
          ) : response.responseType === "photo" ? (
            <PhotoResponse
              key={response.id}
              onCancel={() => remove(response)}
              previousValue={response.content}
            />
          ) : (
            <VideoResponse
              key={response.id}
              onCancel={() => remove(response)}
              previousValue={response.content}
            />
          ))}
        {responding &&
          (responseType === "text" ? (
            <TextResponse
              onCancel={() => setResponding(false)}
              onSave={(response) => handleResponse(response)}
            />
          ) : responseType === "photo" ? (
            <PhotoResponse
              file={responding}
              onCancel={() => setResponding(false)}
              onSave={(response) => handleResponse(response)}
            />
          ) : (
            <VideoResponse
              file={responding}
              onCancel={() => setResponding(false)}
              onSave={(response) => handleResponse(response)}
            />
          ))}
        <RemindMe />
      </Stack>
      <AppBar
        position="fixed"
        color="black"
        sx={{ top: "auto", bottom: "64px" }}
      >
        <Toolbar>
          <MKButton
            variant="text"
            color="white"
            size="small"
            onClick={handleBack}
            disabled={currentStep === 0 || saving}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </MKButton>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <MKTypography variant="body1" color="white">
              Prompt {currentStep + 1}/{maxSteps}
            </MKTypography>
          </Box>

          {currentStep === maxSteps - 1 ? (
            <MKButton
              variant="text"
              color="white"
              size="small"
              onClick={handleFinishClick}
              disabled={saving}
            >
              Finish
            </MKButton>
          ) : (
            <MKButton
              variant="text"
              color="white"
              size="small"
              onClick={handleNext}
              disabled={currentStep === maxSteps - 1 || saving}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </MKButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import analytics from "./";

export const usePageAnalytics = () => {
  const location = useLocation();
  const match = useMatch("dashboard/guestbook/:id/*");
  useEffect(() => {
    if (match) {
      analytics.page({
        path: `dashboard/guestbook/:id${match.params["*"] ? "/" : ""}${
          match.params["*"]
        }`,
      });
    } else {
      analytics.page();
    }
  }, [location]);
};

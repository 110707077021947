import React, { useEffect } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const useDownloadURL = (path) => {
  const [loading, setLoading] = React.useState(true);
  const [url, setUrl] = React.useState();
  const [error, setError] = React.useState();

  const storage = getStorage();
  useEffect(() => {
    if (path) {
      getDownloadURL(ref(storage, path))
        .then((value) => {
          setUrl(value);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
        });
    }
  }, [path]);

  return [loading, url, error];
};
export default useDownloadURL;

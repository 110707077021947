class Signature {
  constructor(id, { uid, message, signature, contact, method, content }) {
    this.id = id;
    this.uid = uid;
    this.message = message;
    this.method = method;
    this.contact = contact;
    this.signature = signature;
    this.sendReminders = !!(contact && method);
    this.content = content;
  }
  getId() {
    return this.id;
  }
  toString() {
    return this.signature;
  }
}

// Firestore data converter
const signatureConverter = {
  toFirestore: ({ uid, message, signature, contact, method, content }) => {
    return {
      uid,
      message,
      signature,
      contact,
      method,
      sendReminders: !!(contact && method),
      content: content || null,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const id = snapshot.id;
    return new Signature(id, data);
  },
};

export default signatureConverter;

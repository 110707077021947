/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import boxShadow from "assets/theme/functions/boxShadow";

const { white, info } = colors;

export default {
  variants: [
    {
      props: { variant: "mk" },
      style: {
        borderRadius: "50%",
        zIndex: 99,
        transition: "all 200ms linear",
        strokeWidth: pxToRem(10),
        width: pxToRem(13),
        height: pxToRem(13),
        background: colors.info.main,
        "&.Mui-active": {
          background: white.main,
          fill: white.main,
          stroke: white.main,
          borderColor: white.main,
          boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
        },

        "&.Mui-completed": {
          background: white.main,
          fill: white.main,
          stroke: white.main,
          borderColor: white.main,
          boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      width: pxToRem(24),
      height: pxToRem(24),
      fill: info.main,
    },
  },
};

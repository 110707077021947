import React from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import ImageCropperPopup from "components/ImageCropper/PopUp";

import { useSetupContext } from "contexts/setup/SetupContextProvider";
import defaultImage from "assets/images/image_placeholder.jpg";

// Analytics
import analytics from "../../../../analytics";

export default function PhotoUpload(props) {
  const { data, setData } = useSetupContext();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    data.image || defaultImage
  );

  const [openCropper, setOpenCropper] = React.useState(false);

  let fileInput = React.createRef();

  const selectFile = (newFile) => {
    setSelectedFile(newFile);
    loadFile(newFile);
    setOpenCropper(true);
  };

  const loadFile = (newFile) => {
    let reader = new FileReader();
    console.log("File", newFile);
    reader.onloadend = () => {
      setFile(newFile);
      setImagePreviewUrl(reader.result);
      setData({ image: reader.result, imageFile: newFile });
    };
    reader.readAsDataURL(newFile);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length <= 0) {
      analytics.track("setup:image_chosen", { file: false });
      return;
    }
    analytics.track("setup:image_chosen");
    selectFile(e.target.files[0]);
  };

  const handleClick = () => {
    analytics.track("setup:select_image_click");
    fileInput.current.click();
  };

  const handleCrop = (dataURL) => {
    const dataURItoFile = async () => {
      const response = await fetch(dataURL);
      const blob = await response.blob();
      return new File([blob], file.name, {
        type: file.type,
        lastModified: new Date(),
      });
    };
    return dataURItoFile().then((cropFile) => {
      loadFile(cropFile);
      setOpenCropper(false);
    });
  };

  let { addButtonProps, changeButtonProps } = props;

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        sx={{ width: "100%" }}
        image={imagePreviewUrl}
        title="guestbook cover photo"
      />
      <CardContent>
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInput}
        />
        <ImageCropperPopup
          open={openCropper}
          src={imagePreviewUrl}
          handleClose={() => {
            setOpenCropper(false);
          }}
          getCroppedData={(data) => {
            console.log("Cropped Image Data", data);
            handleCrop(data);
          }}
        />
      </CardContent>
      <CardActions>
        <MKBox m="auto">
          {file === null ? (
            <MKButton
              color={"dark"}
              {...addButtonProps}
              onClick={() => handleClick()}
            >
              Select image
            </MKButton>
          ) : (
            <Stack direction="row" spacing={2}>
              <MKButton
                color={"dark"}
                {...changeButtonProps}
                onClick={() => handleClick()}
              >
                Change
              </MKButton>
              <MKButton
                color={"dark"}
                {...changeButtonProps}
                onClick={() => {
                  selectFile(selectedFile);
                }}
              >
                Crop
              </MKButton>
            </Stack>
          )}
        </MKBox>
      </CardActions>
    </Card>
  );
}

PhotoUpload.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

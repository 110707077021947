const qrcodeoptions = {
  width: 300,
  height: 300,
  data: "https://quilted.io",
  margin: 10,
  qrOptions: { typeNumber: 0, mode: "Byte", errorCorrectionLevel: "Q" },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0,
    crossOrigin: "anonymous",
  },
  dotsOptions: { type: "classy", color: "#264654" },
  backgroundOptions: { color: "#ffffff" },
  cornersSquareOptions: { type: "extra-rounded", color: "#264654" },
  cornersDotOptions: { color: "#264654" },
};

export default qrcodeoptions;

import * as React from "react";
//import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grow from "@mui/material/Grow";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import FormLabel from "@mui/material/FormLabel";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import MKButton from "components/MKButton";
import { FileUpload } from "components/FileUpload";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import seperator from "assets/images/shapes/heading-seperator.svg";
import { useFinalVideoContext } from "contexts/guestbook/finalVideo/Context";
import { useNavigate } from "react-router";

export default function RevisionForm() {
  const [addCustomMessage, setAddCustomMessage] = React.useState(false);
  const [addCustomOrder, setAddCustomOrder] = React.useState(false);
  const [addMedia, setAddMedia] = React.useState(false);
  const [chooseMusic, setChooseMusic] = React.useState(false);

  const navigate = useNavigate();
  const { files, addFiles, setData, data, uploads, progress, update } =
    useFinalVideoContext();

  const setMessage = (message) => {
    setData({
      ...data,
      customMessage: addCustomMessage ? message : data.customMessage,
    });
  };
  const setOrder = (order) => {
    setData({
      ...data,
      customResponseOrder: addCustomOrder ? order : data.customResponseOrder,
    });
  };
  const setMusicStyle = (musicStyle) => {
    setData({
      ...data,
      customMusicStyle: chooseMusic ? musicStyle : data.customMusicStyle,
    });
  };
  const setNotes = (notes) => {
    setData({
      ...data,
      notes: notes || data.notes,
    });
  };

  /*
  React.useEffect(() => {
    setData({
      customMessage: addCustomMessage ? message : data.customMessage,
      customResponseOrder: addCustomOrder ? order : data.customResponseOrder,
      customMusicStyle: chooseMusic ? musicStyle : data.customMusicStyle,
      notes: notes || data.notes,
    });
  }, [message, order, musicStyle, notes, addCustomMessage, addCustomOrder]);
  // may need to handle each change and bypass state.
  */

  const handleMusicStyleChange = (event) => {
    setMusicStyle(event.target.value);
  };

  const fileUploadProp = {
    accept: "image/*,video/*,audio/*",
    onChange: (event) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        addFiles(event.target.files);
      }
    },
    onDrop: (event) => {
      if (
        event.dataTransfer.files !== null &&
        event.dataTransfer?.files?.length > 0
      ) {
        addFiles(event.dataTransfer.files);
      }
    },
  };

  const revise = async () => {
    await update().then(() => {
      navigate("../", { relative: "path" });
    });
  };

  return (
    <React.Fragment>
      <Stack direction="column" spacing={2} mb={2}>
        <Box>
          <Box textAlign="center">
            <Typography
              align="center"
              variant="h2"
              component="h2"
              sx={{ p: 2, pb: 0 }}
            >
              Revision Request
            </Typography>
            <img src={seperator} />
          </Box>
        </Box>
        <Typography variant="body2" gutterBottom>
          How can we take your video to the next level? Fill out the form below,
          and we’ll make it happen!
        </Typography>
        <MKTypography variant="h6">
          How would you like to customize your video?
        </MKTypography>
        <FormControlLabel
          label={
            <MKTypography variant="body2">Change Custom Message</MKTypography>
          }
          control={
            <Switch
              color="info"
              checked={addCustomMessage}
              onChange={(event) => {
                setAddCustomMessage(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
        <Box sx={addCustomMessage ? {} : { display: "none" }}>
          <Grow in={addCustomMessage}>
            <MKInput
              label="Message"
              multiline
              fullWidth
              rows={5}
              placeholder="Tell us what you would like your custom message to say, and at what point in the video you would like it to be featured."
              value={data.customMessage}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grow>
        </Box>
        <FormControlLabel
          label={
            <MKTypography variant="body2">
              Change Order of Responses
            </MKTypography>
          }
          control={
            <Switch
              color="info"
              checked={addCustomOrder}
              onChange={(event) => {
                setAddCustomOrder(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
        <Box sx={addCustomOrder ? {} : { display: "none" }}>
          <Grow in={addCustomOrder}>
            <MKInput
              label="Message"
              multiline
              fullWidth
              rows={5}
              placeholder="Tell us what order you would like your responses to be featured"
              value={data.customResponseOrder}
              onChange={(e) => {
                setOrder(e.target.value);
              }}
            />
          </Grow>
        </Box>
        <FormControlLabel
          label={
            <MKTypography variant="body2">
              Add My Own Photos, Videos
            </MKTypography>
          }
          control={
            <Switch
              color="info"
              checked={addMedia}
              onChange={(event) => {
                setAddMedia(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
        {addMedia && (
          <Box>
            <FileUpload {...fileUploadProp} />
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              {files &&
                files.map((file, index) => (
                  <ListItem key={file.name}>
                    <ListItemIcon>
                      {uploads[index]?.uploaded ? (
                        <CloudDoneIcon color="success" />
                      ) : uploads[index]?.uploading ? (
                        <CloudUploadIcon color="info" />
                      ) : (
                        <CloudQueueIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{
                        fontSize: "0.85rem",
                        fontWeight: "medium",
                        letterSpacing: 0,
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        )}
        <FormControlLabel
          label={
            <MKTypography variant="body2">Change Music Style</MKTypography>
          }
          control={
            <Switch
              color="info"
              checked={chooseMusic}
              onChange={(event) => {
                setChooseMusic(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
        {chooseMusic && (
          <Box>
            <MKTypography variant="body2">
              Choose your preferred style of background music:
            </MKTypography>
            <FormControl sx={{ m: 3 }} error={false} variant="filled">
              <FormLabel id="music-styles">Music Style</FormLabel>
              <RadioGroup
                aria-labelledby="music-styles"
                name="musicStyle"
                value={data.customMusicStyle}
                onChange={handleMusicStyleChange}
              >
                <FormControlLabel
                  value="upbeat"
                  control={<Radio size="small" />}
                  label={
                    <MKTypography variant="body2">Upbeat/Joyous</MKTypography>
                  }
                />
                <FormControlLabel
                  value="slow"
                  control={<Radio />}
                  label={
                    <MKTypography variant="body2">
                      Slow/Melancholic
                    </MKTypography>
                  }
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label={<MKTypography variant="body2">Both/Mix</MKTypography>}
                />
              </RadioGroup>
              <FormHelperText>Choose an option</FormHelperText>
            </FormControl>
          </Box>
        )}
        <Box pt={2}>
          <MKTypography variant="body2">
            Any additional notes or requests for our video editors:
          </MKTypography>
          <Box>
            <MKInput
              label="Message"
              multiline
              fullWidth
              rows={5}
              placeholder="Expectations on delivery date, responses to leave out, etc."
              value={data.notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Box>
        </Box>
        {progress && <LinearProgress variant="determinate" value={progress} />}
        <Box sx={{ textAlign: "center" }} pt={2}>
          <MKButton
            variant="gradient"
            color="info"
            onClick={() => revise()}
            size="large"
          >
            <CheckCircleIcon />
            &nbsp;Submit for Revision
          </MKButton>
        </Box>
      </Stack>
    </React.Fragment>
  );
}

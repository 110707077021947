import * as React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import PricingCard from "components/PricingCard";
import getUpgradeInfo from "utils/upgradeInfo";

const packageProps = {
  isCurrent: PropTypes.bool.isRequired,
  price: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
  }),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  includeAllSpecs: PropTypes.bool,
};
const defaultProps = {
  includeAllSpecs: false,
};

const silverPackage = {
  variant: "silver",
  title: "Silver",
  description: "Ideal for budget-concious couples...",
  specifications: [
    { label: "Global Guest Participation", includes: true },
    { label: "Instant Access to Content", includes: true },
    { label: "Unlimited Invitations", includes: true },
    { label: "Unlimited Media Storage", includes: true },
    { label: "Custom URL & QR Code", includes: true },
    { label: "Downloadable Video Montage", includes: false },
    { label: "Professional Video Editing", includes: false },
    { label: "Full Video Customization", includes: false },
    { label: "2 Rounds of Revisions", includes: false },
  ],
};

export function SilverPackage({ isCurrent, action, price }) {
  return (
    <PricingCard
      {...silverPackage}
      action={action}
      price={price}
      current={isCurrent}
    />
  );
}
SilverPackage.propTypes = {
  ...packageProps,
};
SilverPackage.defaultProps = {
  ...defaultProps,
};

const goldPackage = {
  variant: "gold",
  title: "Gold",
  description:
    "Perfect for couples seeking a step above, desiring not only to collect special messages but also to weave them into a memorable keepsake video, stitched together by our professional video editors, commemorating their wedding day.",
  specifications: [
    { label: "All Silver Package Features", includes: true },
    { label: "Downloadable Video Montage", includes: true },
    { label: "Professional Video Editing", includes: true },
    { label: "Full Video Customization", includes: false },
    { label: "2 Rounds of Revisions", includes: false },
  ],
};

const getFullGoldSpecs = () => {
  const specifications = update([], { $push: goldPackage.specifications });
  specifications.shift();
  let specs = update([], { $push: silverPackage.specifications }).filter(
    (spec) => spec.includes
  );
  specs = update(specs, { $toggle: ["includes"] });
  //specifications.unshift(...specs);
  return update(specifications, { $unshift: specs });
};

const getFullDiamondSpecs = () => {
  const specifications = update([], { $push: diamondPackage.specifications });
  specifications.shift();
  let specs = update([], { $push: getFullGoldSpecs() }).filter(
    (spec) => spec.includes
  );
  specs = update(specs, { $toggle: ["includes"] });
  //specifications.unshift(...specs);
  return update(specifications, { $unshift: specs });
};

export function GoldPackage({ isCurrent, action, price, includeAllSpecs }) {
  const props = { ...goldPackage };

  if (includeAllSpecs) {
    props.specifications = getFullGoldSpecs();
  }

  return (
    <PricingCard {...props} action={action} price={price} current={isCurrent} />
  );
}
GoldPackage.propTypes = {
  ...packageProps,
};
GoldPackage.defaultProps = {
  ...defaultProps,
};

const diamondPackage = {
  variant: "diamond",
  title: "Diamond",
  description:
    "The ultimate package for couples who appreciate fine details and customization, offering in-depth professional video touch-ups, full edit flexibility	, and two rounds of revisions to create a perfectly polished keepsake of their special day.",
  specifications: [
    { label: "All Gold Package Features", includes: true },
    { label: "Full Video Customization", includes: true },
    { label: "2 Rounds of Revisions", includes: true },
  ],
};

export function DiamondPackage({ isCurrent, action, price, includeAllSpecs }) {
  const props = { ...diamondPackage };

  if (includeAllSpecs) {
    props.specifications = getFullDiamondSpecs();
  }

  return (
    <PricingCard
      {...props}
      action={action}
      price={isCurrent ? null : price}
      current={isCurrent}
    />
  );
}
DiamondPackage.propTypes = {
  ...packageProps,
};

export default function PackageCards({ current, show }) {
  const [upgradeInfo, setUpgradeInfo] = React.useState({
    gold: undefined,
    diamond: undefined,
  });

  React.useEffect(() => {
    if (current && current !== "diamond") {
      const upgrade = getUpgradeInfo(current);
      setUpgradeInfo(upgrade);
    }
  }, [current]);

  return (
    <Stack direction="column" spacing={2} mb={2}>
      {(show === "all" || (show === "current" && current === "silver")) && (
        <SilverPackage isCurrent={current === "silver"} />
      )}
      {(show === "all" ||
        (show === "current" && current === "gold") ||
        (show === "upgrades" && current === "silver")) && (
        <GoldPackage
          price={{ value: upgradeInfo.gold?.price || 0 }}
          action={
            current === "gold"
              ? undefined
              : {
                  type: "external",
                  route: upgradeInfo.gold?.url, //"https://quilted.io/cart/42649012535454:1"
                  label: "upgrade",
                }
          }
          isCurrent={current === "gold"}
        />
      )}

      {(show === "all" ||
        (show === "current" && current === "diamond") ||
        (show === "upgrades" && current !== "diamond")) && (
        <DiamondPackage
          price={{ value: upgradeInfo.diamond?.price || 0 }}
          action={
            current === "diamond"
              ? undefined
              : {
                  type: "internal",
                  route: upgradeInfo.diamond?.url,
                  label: "upgrade",
                }
          }
          isCurrent={current === "diamond"}
        />
      )}
    </Stack>
  );
}

PackageCards.propTypes = {
  current: PropTypes.oneOf(["silver", "gold", "diamond"]).isRequired,
  show: PropTypes.oneOf(["all", "current", "upgrades"]),
};
PackageCards.defaultProps = {
  show: "all",
};

import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import mixpanelPlugin from "@analytics/mixpanel";

// eslint-disable-next-line no-undef
const gaMeasurementId = process.env.REACT_APP_GA_TRACKING_ID || "";
// eslint-disable-next-line no-undef
const mixpanelId = process.env.REACT_APP_MIXPANEL_TOKEN || "";
const debug = /^true$/i.test(process.env.REACT_APP_ANALYTICS_DEBUG || "false");

/* This is an example plugin */
function loggingProviderPlugin(userConfig = {}) {
  return {
    NAMESPACE: "provider-example",
    config: userConfig,
    initialize: ({ payload }) => {
      console.log("Initialize:", payload);
    },
    page: ({ payload }) => {
      console.log(`Page > [payload: ${JSON.stringify(payload, null, 2)}]`);
    },
    /* Track event */
    track: ({ payload }) => {
      console.log(
        `Track > [${payload.event}] [payload: ${JSON.stringify(
          payload,
          null,
          2
        )}]`
      );
    },
    /* Identify user */
    identify: ({ payload }) => {
      console.log(`identify > [payload: ${JSON.stringify(payload, null, 2)}]`);
    },
    loaded: () => {
      return true;
    },
    ready: () => {
      console.log("ready: loggingProviderPlugin");
    },
  };
}

/* initialize analytics and load plugins */
const analytics = Analytics({
  debug,
  plugins: [
    loggingProviderPlugin(),
    googleAnalytics({
      measurementIds: [gaMeasurementId],
    }),
    mixpanelPlugin({
      token: mixpanelId,
    }),
  ],
});
window.Analytics = analytics;
export default analytics;

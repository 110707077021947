import * as React from "react";
import { useLocation } from "react-router-dom";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

import Skeleton from "@mui/material/Skeleton";
import LinearProgress from "@mui/material/LinearProgress";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ConfettiBox from "components/ConfettiBox";
import Sharing from "./components/Sharing";

import { db } from "../../firebase";
export default function SetupCompletePage() {
  const [slug, setSlug] = React.useState();

  const location = useLocation();
  const { id } = location.state;
  const [guestbook, loading, error] = useDocument(doc(db, "guestbook", id), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  React.useEffect(() => {
    if (!loading && guestbook && guestbook.exists()) {
      setSlug(guestbook.data().slug);
    }
  }, [guestbook, loading]);

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      <ConfettiBox pt={6}>
        {loading && (
          <MKBox sx={{ p: 4 }}>
            <Skeleton
              animation="wave"
              height={20}
              width="20%"
              sx={{ mx: "auto", marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="30%"
              sx={{ mx: "auto", marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="20%"
              sx={{ mx: "auto", marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={300}
              width="30%"
              sx={{ mx: "auto" }}
            />
          </MKBox>
        )}
        {error && <MKTypography>ERROR: {error}</MKTypography>}
        {slug && <Sharing slug={slug} />}
      </ConfettiBox>
    </React.Fragment>
  );
}

import buildURL from "utils/buildUrl";

const actionCodeSettings = {
  // URL you want to redirect back to.
  url: undefined,
  handleCodeInApp: true,
};

export default function buildEmailActionCodeSettings(slug) {
  const url = buildURL({ slug });
  return { ...actionCodeSettings, url };
}

import * as React from "react";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

const variants = ["h1", "h3", "body1", "caption"];

export default function Loading() {
  return (
    <div>
      <Skeleton animation="wave" height={100} style={{ marginBottom: 6 }} />
      {variants.map((variant) => (
        <Typography component="div" key={variant} variant={variant}>
          <Skeleton />
        </Typography>
      ))}
      <Skeleton animation="wave" height={100} style={{ marginBottom: 6 }} />
    </div>
  );
}

import * as React from "react";
import { Outlet } from "react-router-dom";

// Material UI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

function SetupLayout() {
  return (
    <>
      <AppBar position="static" color="black">
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Typography variant="h6" color="inherit" component="div">
              Guestbook Setup
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Paper square sx={{ pb: "50px" }}>
        <Outlet />
      </Paper>
    </>
  );
}

export default SetupLayout;

import React, { useMemo } from "react";
import { debounce } from "@mui/material/utils";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import AddCircle from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import update from "immutability-helper";
import Prompt, { AddPromptCard } from "./Prompt";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import { useSetupContext } from "contexts/setup/SetupContextProvider";

// Analytics
import analytics from "../../../../analytics";

export default function PromptsList() {
  const { data, setData } = useSetupContext();
  const { prompts } = data;
  const setPrompts = (newPrompts) => {
    setData({ prompts: newPrompts });
  };
  const [active, setActive] = React.useState("");
  const [addNew, setAddNew] = React.useState(false);
  const [newPrompt, setNewPrompt] = React.useState();

  const maxPrompts = 3;

  const handleSaveNew = () => {
    analytics.track("setup:save_new_prompt", {
      text: newPrompt,
    });

    //update prompts
    setPrompts(
      update(prompts, {
        $push: [
          {
            id: prompts.length + 1,
            text: newPrompt,
          },
        ],
      })
    );
    setAddNew(false);
    setNewPrompt(null);
  };

  const handleMove = React.useCallback(
    (currentIndex, index) => {
      analytics.track("setup:move_prompt");
      if (index < 0 || index >= prompts.length) return;
      const prompt = prompts[currentIndex];
      const newCollection = update(prompts, {
        $splice: [
          [currentIndex, 1],
          [index, 0, prompt],
        ],
      });
      setPrompts(newCollection);
    },
    [prompts]
  );

  const handleDelete = React.useCallback(
    (index) => {
      analytics.track("setup:delete_prompt", { prompt: prompts[index].text });
      const newCollection = update(prompts, {
        $splice: [[index, 1]],
      });
      setPrompts(newCollection);
    },
    [prompts]
  );

  const handleAddNew = () => {
    analytics.track("setup:add_new_prompt_clicked");
    setAddNew(true);
  };

  const handleSelect = (card) => {
    analytics.track("setup:prompt_selected", card);
    setActive(card.id);
  };

  const debouncedSetPrompt = useMemo(
    () =>
      debounce((value) => {
        analytics.track("setup:new_prompt_changed", { value });
      }, 750),
    []
  );

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
      {prompts.map((card, index) => (
        <Prompt
          text={card.text}
          index={index}
          key={card.text}
          isActive={active === card.id}
          onSelect={() => handleSelect(card)}
          onMove={(_i, change) => handleMove(index, change)}
          onDelete={() => handleDelete(index)}
        />
      ))}
      {maxPrompts > prompts.length && (
        <AddPromptCard>
          <Collapse in={addNew} timeout="auto" unmountOnExit>
            <CardContent>
              <MKBox
                variant="contained"
                bgColor="white"
                p={4}
                sx={{ borderRadius: "2.5rem" }}
              >
                <MKInput
                  color="white"
                  label="Add a Custom Prompt"
                  multiline
                  fullWidth
                  rows={6}
                  placeholder="e.g. What words of wisdom do you have for us?"
                  value={newPrompt}
                  onChange={(e) => {
                    debouncedSetPrompt(e.target.value);
                    setNewPrompt(e.target.value);
                  }}
                  onFocus={() => analytics.track("setup:new_prompt_focus")}
                  onBlur={() => analytics.track("setup:new_prompt_blur")}
                />
              </MKBox>
            </CardContent>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {addNew ? (
              <IconButton onClick={() => handleSaveNew()}>
                <CheckCircleIcon color="white" />
              </IconButton>
            ) : (
              <IconButton onClick={handleAddNew}>
                <AddCircle color="white" />
              </IconButton>
            )}
          </Box>
        </AddPromptCard>
      )}
    </Box>
  );
}

import { getAuth, onAuthStateChanged } from "firebase/auth";
import analytics from "../analytics";

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    analytics.identify(uid, { anon: user.isAnonymous });
    // ...
  } else {
    // User is signed out
    // ...
  }
});

import MKBox from "components/MKBox";
import PhotoUpload from "./PhotoUpload";
import MKTypography from "components/MKTypography";
// https://codesandbox.io/s/react-cropper-js-mui-with-rotate-and-flip-option-y7mm6n?file=/src/Cropper.js

export default function Photo() {
  return (
    <MKBox component="section" py={4} maxWidth="400px">
      <MKTypography>
        What featured image would you like displayed on your Digital Guestbook
        Welcome Page?
      </MKTypography>
      <MKTypography variant="subtitle2">
        We recommend a square photo at least 1000 px wide
      </MKTypography>
      <PhotoUpload />
    </MKBox>
  );
}

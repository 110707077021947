export const useClickToCopy = (value, onCopy) => {
  const copy = (e) => {
    e.preventDefault();
    //@ts-ignore
    const dummy = document.createElement("input");
    //@ts-ignore
    document.body.appendChild(dummy);
    dummy.setAttribute("value", value);
    dummy.select();
    //@ts-ignore
    document.execCommand("copy");
    //@ts-ignore
    document.body.removeChild(dummy);
    onCopy && onCopy();
  };

  return { onClick: copy };
};

export default useClickToCopy;

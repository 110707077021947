import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";

import MKProgress from "components/MKProgress";
import ResponseButton from "../ResponseButton";

import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";

import defaultImage from "assets/images/image_placeholder.jpg";

export default function VideoResponse({
  file,
  previousValue,
  onCancel,
  onSave,
}) {
  const { saving, progress } = useGuestbookContext();
  const [previewUrl, setPreviewUrl] = useState(
    previousValue && previousValue.url
  );

  const handleSave = () => {
    onSave && onSave({ content: file, responseType: "video" });
  };

  const handleFileChange = useCallback(() => {
    if (!file) return;
    setPreviewUrl((previous) => {
      if (previous) {
        URL.revokeObjectURL(previous);
      }
      return URL.createObjectURL(file);
    });
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [file]);

  useEffect(() => {
    handleFileChange();
  }, [file]);

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!previewUrl && (
          <img
            src={defaultImage}
            style={{ maxWidth: "100%", maxHeight: "50vh" }}
          />
        )}
        {previewUrl && (
          <video
            style={{ maxWidth: "100%", maxHeight: "50vh" }}
            controls
            key={previewUrl}
          >
            <source src={previewUrl} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        )}
      </Box>
      {saving && <MKProgress value={progress} color="primary" />}
      <Stack direction="row">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <ResponseButton
            color="error"
            icon={<CancelIcon />}
            label="Delete"
            variant="text"
            size="large"
            onClick={onCancel}
            disabled={saving}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {!previousValue && (
            <ResponseButton
              color="success"
              icon={<CheckIcon />}
              label="Save"
              variant="text"
              size="large"
              onClick={handleSave}
              disabled={!file || saving}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
}

VideoResponse.propTypes = {
  file: PropTypes.object,
  previousValue: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

import * as React from "react";
//import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import seperator from "assets/images/shapes/heading-seperator.svg";

import PackageCards from "../PackageCards";

export default function SilverUpgrade() {
  // Help!
  return (
    <React.Fragment>
      <Stack direction="column" spacing={2} mb={2}>
        <Box textAlign="center">
          <Typography
            align="center"
            variant="h2"
            component="h2"
            sx={{ p: 2, pb: 0 }}
          >
            Final Video
          </Typography>
          <img src={seperator} />
        </Box>

        <Typography variant="body2" gutterBottom>
          Congrats! You’ve captured the well-wishes of your loved ones, and now
          we invite you to take your guestbook to the next level.
        </Typography>
        <Typography variant="body2" gutterBottom>
          As a reminder, your Silver package gives you unlimited invitations and
          participation from your friends and family to share videos, photos,
          and text responses. You can view and download all responses directly
          from your dashboard.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Interested in having all of these stitched together in a final
          keepsake movie? Let a pro help by upgrading to one of our packages
          below.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Here’s what might be missing from your Quilted experience:
        </Typography>
        <PackageCards current="silver" show="upgrades" />
      </Stack>
    </React.Fragment>
  );
}

import update from "immutability-helper";
import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_CURRENT_STEP,
  SAVING,
} from "contexts/shared/constants";
import {
  SET_GUESTBOOK,
  SIGN_GUESTBOOK,
  SAVE_RESPONSE,
  REMOVE_RESPONSE,
  SET_RESPONSES,
  SET_LOADING,
} from "./constants";

export const defaultGuestbookState = {
  loading: true,
  guestbook: null,
  signed: null, //true, Set to null as it is the initial value, set to true for testing.
  responses: [],
  saving: false,
  progress: 0,
  maxSteps: 0, // SET BASED ON THE NUMBER OF PROMPTS DURING SET_GUESTBOOK.
  currentStep: 0,
};

const stepsReducer = (state = defaultGuestbookState, action) => {
  const { currentStep, maxSteps } = state;
  const { type, payload } = action;
  switch (type) {
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: Math.min(currentStep + 1, maxSteps - 1),
      };
    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: Math.max(0, currentStep - 1),
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: payload,
      };
    default:
      return state;
  }
};

export const reducer = (state = defaultGuestbookState, action) => {
  const { responses } = state;
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_GUESTBOOK:
      return {
        ...state,
        guestbook: payload,
        maxSteps: payload.prompts.length,
      };
    case SIGN_GUESTBOOK:
      return {
        ...state,
        signed: payload,
        saving: false,
        progress: 0,
      };
    case SET_RESPONSES:
      return {
        ...state,
        responses: [...payload],
      };
    case SAVE_RESPONSE:
      return stepsReducer(
        {
          ...state,
          responses: [...responses, payload],
          saving: false,
          progress: 0,
        },
        { type: INCREMENT_CURRENT_STEP }
      );
    case REMOVE_RESPONSE: {
      const index = responses.findIndex(
        (response) => response.id === payload.id
      );
      // Delete at a specific index, no matter what value is in it
      const newResponses =
        index >= 0
          ? update(responses, { $splice: [[index, 1]] })
          : [...responses];
      return {
        ...state,
        responses: newResponses,
      };
    }
    case SAVING: {
      return {
        ...state,
        saving: !!(payload > 0 && payload < 100),
        progress: payload,
      };
    }

    default:
      return stepsReducer(state, action);
  }
};

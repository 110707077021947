import { useMemo } from "react";
import { debounce } from "@mui/material/utils";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import { useSetupContext } from "contexts/setup/SetupContextProvider";
// Analytics
import analytics from "../../../analytics";

export default function Message() {
  const { data, setData } = useSetupContext();
  const { message } = data;

  const debouncedSetMessage = useMemo(
    () =>
      debounce((value) => {
        analytics.track("setup:message_changed", { value });
      }, 750),
    []
  );
  const sampleMessage = `e.g. Thank you so much for signing our guestbook. It means the world to us! We can’t wait to hear the lovely messages you leave for us!
  - Love K & J`;

  return (
    <MKBox component="section" py={4} maxWidth="400px">
      <MKTypography>Write a brief message to your guests.</MKTypography>
      <MKInput
        label="Custom Message"
        multiline
        fullWidth
        rows={6}
        placeholder={sampleMessage}
        value={message}
        onChange={(e) => {
          debouncedSetMessage(e.target.value);
          setData({ message: e.target.value });
        }}
        onFocus={() =>
          analytics.track("setup:message_focus", { value: message })
        }
        onBlur={() => {
          analytics.track("setup:message_blur", { value: message });
        }}
      />
      <MKTypography variant="caption">
        This will be the first thing they read after clicking the link to sign
        your Digital Guestbook. You can thank them for their participation, let
        them know how excited you are to see their responses, give them
        additional context, or whatever feels right!
      </MKTypography>
    </MKBox>
  );
}

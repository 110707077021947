import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";

import CreateNewCard from "./components/CreateNewCard";
import { useAuthContext } from "contexts/AuthContextProvider";
import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";
import Loading from "pages/guestbook/components/Loading";
import { Navigate } from "react-router-dom";

export default function DashboardPage() {
  const { user } = useAuthContext();
  let ref = collection(db, "guestbook");
  const q = query(ref, where("uid", "==", user.uid || "X")).withConverter(
    guestbookConverter
  );

  const [values, loading, error] = useCollectionData(q);

  return (
    <>
      <Paper square>
        <Typography variant="h1" gutterBottom sx={{ p: 2, pb: 0 }}>
          {loading
            ? "Loading Your Digital Guestbook"
            : (!values || !values.length) && !error
            ? "Welcome! Create Your Digital Guestbook!"
            : "Opening Digital Guestbook"}
        </Typography>
        {loading && <Loading />}
        {error && <Typography>error</Typography>}
      </Paper>{" "}
      <Stack
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        {(!values || !values.length) && !loading && <CreateNewCard />}
        {!!values && values.length && (
          <Navigate to={`guestbook/${values[0]?.id}`} replace />
        )}
      </Stack>
    </>
  );
}

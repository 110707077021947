import * as React from "react";
//import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";
import Loading from "pages/guestbook/components/Loading";

import { FinalVideoContextProvider } from "contexts/guestbook/finalVideo/Context";
import RevisionForm from "./components/FinalVideo/RevisionForm";

export default function FinalRevisionsPage() {
  const { id } = useParams();
  const document = doc(db, "guestbook", id);
  const ref = document.withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(ref);

  const fvrRef = doc(db, "finalVideoRequests", id);
  const [data, data_loading, data_error] = useDocumentData(fvrRef);

  if ((loading && !guestbook) || data_loading) return <Loading />;
  if (error || data_error) return <Alert severity="error">Error</Alert>;
  return (
    <React.Fragment>
      <FinalVideoContextProvider guestbookId={id} data={data}>
        <Paper square sx={{ pt: "2rem" }}>
          <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
            {guestbook.tier === "diamond" && data.revisions < 2 ? (
              <RevisionForm />
            ) : (
              <>Denied</>
            )}
          </Box>
        </Paper>
      </FinalVideoContextProvider>
    </React.Fragment>
  );
}

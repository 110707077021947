import React from "react";
import {
  useReducer,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  collection,
  getDoc,
  doc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { defaultInvitesState, reducer } from "./store";
import { useAuthContext } from "contexts/AuthContextProvider";
import { SAVING } from "contexts/shared/constants";
import { SET_LOADING, SET_INVITATION_SETTINGS } from "./constants";
import buildURL from "utils/buildUrl";

export const GUESTBOOK_COLLECTION_NAME = "guestbook";
export const COMMUNICATIONS_COLLECTION_NAME = "communications";
export const INVITATIONS_NAME = "invitations";

export const buildCommunicationsCollectionName = (guestbookId) => {
  const parts = [
    GUESTBOOK_COLLECTION_NAME,
    guestbookId,
    COMMUNICATIONS_COLLECTION_NAME,
  ];
  return parts.join("/");
};

export const buildInvitationsCollectionName = (guestbookId) => {
  const parts = [GUESTBOOK_COLLECTION_NAME, guestbookId, INVITATIONS_NAME];
  return parts.join("/");
};

export const InvitesContext = createContext(defaultInvitesState);

export const InvitesContextProvider = ({ guestbook, children }) => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    ...defaultInvitesState,
    guestbookId: id,
    guestbook,
  });

  const getGuestbookInvitationSettings = async () => {
    try {
      const ref = doc(
        db,
        buildCommunicationsCollectionName(id),
        INVITATIONS_NAME
      );
      const invitationsDoc = await getDoc(ref);
      if (invitationsDoc.exists()) {
        dispatch({
          type: SET_INVITATION_SETTINGS,
          payload: invitationsDoc.data(),
        });
        return invitationsDoc.data();
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGuestbookInvitationSettings()
      .finally(() => {
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch((e) => {
        console.error(
          "There was an error loading the guestbook invitations.",
          e
        );
      });
  }, []);

  return (
    <InvitesContext.Provider value={[state, dispatch]}>
      {children}
    </InvitesContext.Provider>
  );
};

InvitesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  guestbook: PropTypes.any.isRequired,
};

export const useInvitesContext = () => {
  const auth = useAuthContext();
  const [state, dispatch] = useContext(InvitesContext);
  const { loading, guestbookId, guestbook, settings, saving, progress } = state;
  const [error, setError] = React.useState();

  if (!InvitesContext) {
    throw new Error(
      "useInvitesContext should be used inside InvitesContextProvider"
    );
  }

  const saveSettings = async ({ message, signature }) => {
    try {
      const ref = doc(
        db,
        buildCommunicationsCollectionName(guestbookId),
        INVITATIONS_NAME
      );
      const invitationsDoc = await setDoc(
        ref,
        { message, signature },
        { merge: true }
      );
      if (invitationsDoc.exists()) {
        dispatch({
          type: SET_INVITATION_SETTINGS,
          payload: { message, signature },
        });
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveInvitations = async (invites) => {
    const link = buildURL({ slug: `${guestbook.slug}/guestbook` });
    // Get a new write batch
    const batch = writeBatch(db);
    [...invites].forEach((invite) => {
      const ref = doc(
        collection(db, buildInvitationsCollectionName(guestbookId))
      );
      batch.set(ref, {
        ...invite,
        title: guestbook.title,
        link,
        state: "PREPARING", //"PENDING" | "PROCESSING" | "SUCCESS" | "ERROR"
        photo: guestbook.image,
      });
    });
    // Commit the batch
    await batch.commit();
  };

  const save = useCallback(
    async (data) => {
      dispatch({ type: SAVING, payload: 1 });
      //save settings
      if (!data) setError("Error");
      await saveSettings(data);
      dispatch({ type: SAVING, payload: 50 });
      //save invitation docs
      await saveInvitations(data.invitations);

      dispatch({ type: SAVING, payload: 100 });
    },
    [dispatch, auth]
  );

  return {
    loading,
    guestbookId,
    settings,
    error,
    saving,
    progress,
    save,
  };
};

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import analytics from "../../../analytics";

const Item = styled(Box)(() => ({
  textAlign: "center",
}));

export default function CreateNewCard() {
  const navigate = useNavigate();
  return (
    <Card sx={{ maxWidth: 200, m: 4, mx: "auto" }} variant="primary">
      <CardActionArea
        onClick={() => {
          analytics.track("new_guestbook:click");
          navigate("/setup");
        }}
      >
        <CardContent>
          <Stack>
            <Item>
              <AddCircleIcon fontSize="large" />
              <Typography variant="body2">New Guestbook</Typography>
            </Item>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";

const getInitials = function (name) {
  const parts = name.split(" ");
  let initials = "";
  for (var i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials;
};
/*
* https://jsfiddle.net/brandonmilleraz/h1knjq3e/44/
const regex = /&|,|\sand\s/;
const containsMultiple = function (name) {
  const parts = name.split(regex);
  return parts.length > 1;
};

const splitNames = function (name) {
  const nameParts = name.split(regex);
  const inits = nameParts.map((n) => getInitials(n));
  inits.forEach((init, i) => {
    if (init.length === 1) {
      const last = inits.filter((peep) => peep.length > 1);
      if (last && last.length > 0 && last[last.length - 1].length > 1) {
        const c = last[last.length - 1].substr(-1);
        init += c;
        inits[i] = init;
      }
    }
  });
  return inits;
};
*/

function stringAvatar(name) {
  return {
    children: `${getInitials(name)}`,
    alt: name,
  };
}

export default function SignatureAvatar({ signature, initialsOnly }) {
  return signature.content && !initialsOnly ? (
    <Avatar alt={signature.signature} src={signature.content.url} />
  ) : (
    <Avatar {...stringAvatar(signature.signature)} />
  );
}

SignatureAvatar.propTypes = {
  signature: PropTypes.object.isRequired,
  initialsOnly: PropTypes.bool,
};

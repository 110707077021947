import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_STEPS,
  SET_CURRENT_STEP,
  SAVING,
} from "contexts/shared/constants";
import { SET_DATA, COMPLETE } from "./constants";

const defaultPrompts = [
  {
    id: 1,
    text: "What words of wisdom, advice or wishes do you have for us?",
  },
  {
    id: 2,
    text: "What's a favorite memory that comes to mind that always makes you smile or laugh?",
  },
  {
    id: 3,
    text: "Share anything else you'd like to in honor of the newlyweds!",
  },
];

export const defaultSetupState = {
  steps: [],
  currentStep: 0,
  data: { prompts: defaultPrompts }, // guestbook data structure
  saving: false,
  progress: 0,
};

const stepsReducer = (state = defaultSetupState, action) => {
  const { currentStep, steps } = state;
  const { type, payload } = action;
  switch (type) {
    case SET_STEPS:
      return {
        ...state,
        steps: payload.steps,
      };
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: currentStep < steps.length ? currentStep + 1 : currentStep,
      };
    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        previousStep: currentStep,
        currentStep: payload,
      };
    default:
      return state;
  }
};
/*
const validatex = (state) => {
  //Validation on Change.
  const currentStep = Math.min(state.currentStep, state.steps.length - 1);
  const isValid = state.steps[currentStep].validate(state);
  state.steps[state.currentStep].isValid = isValid;
  const allValid = state.steps.every((step) => step.isValid);
  return { ...state, currentStep: allValid ? state.currentStep : currentStep };
};
*/

const validate = (state) => {
  const currentStep = Math.min(state.currentStep, state.steps.length - 1);
  for (let index = 0; index <= currentStep; index++) {
    const isValid = state.steps[index].validate(state.data);
    state.steps[index].isValid = isValid;
  }
  const allValid = state.steps.every((step) => step.isValid);
  return { ...state, currentStep: allValid ? state.currentStep : currentStep };
};

export const reducer = (state = defaultSetupState, action) => {
  const { data } = state;
  const { type, payload } = action;
  switch (type) {
    case SAVING: {
      return {
        ...state,
        saving: !!(payload > 0 && payload < 100),
        progress: payload,
      };
    }
    case SET_DATA:
      return validate({
        ...state,
        data: { ...data, ...payload.data },
      });
    case COMPLETE:
      return stepsReducer(
        {
          ...state,
          data: { ...data, ...payload },
          saving: false,
          progress: 100,
        },
        { ...action, type: INCREMENT_CURRENT_STEP }
      );
    default:
      return validate(stepsReducer(state, action));
  }
};

import * as React from "react";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";

import GridItem from "components/Grid/GridItem";
import ActionButton from "components/ActionButton";
import ShareBox from "components/ShareBox";
import { InvitesContextProvider } from "contexts/guestbook/invites/InvitesContextProvider";
import SendInvitations from "./components/SendInvitations";
import SentInvitesTable from "./components/SentInvitesTable";
import useLocalStorage from "hooks/useLocalStorage";

import seperator from "assets/images/shapes/heading-seperator.svg";
import Loading from "pages/guestbook/components/Loading";

export default function GuestbookInvitationsPage() {
  //const { user } = useAuthContext();
  const { id } = useParams();
  const docRef = doc(db, "guestbook", id).withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(docRef);
  const [share, setShare] = React.useState(false);

  const ref = React.useRef(null);
  const [showTipOne, setShowTipOne] = useLocalStorage("tip-1-is-open", true);
  const executeScroll = () => ref.current.scrollIntoView();

  if (loading && !guestbook) return <Loading />;
  if (error) return "error";
  return (
    <React.Fragment>
      <InvitesContextProvider guestbook={guestbook}>
        <Paper square sx={{ pt: "2rem" }}>
          <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
            <Stack direction="column" spacing={2} mb={2}>
              <Box>
                <Box textAlign="center">
                  <Typography
                    align="center"
                    variant="h2"
                    component="h2"
                    sx={{ p: 2, pb: 0 }}
                  >
                    Invites
                  </Typography>
                  <img src={seperator} />
                </Box>
                <Collapse in={showTipOne}>
                  <Alert
                    severity="info"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowTipOne(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Let Quilted take on the heavy lifting for you by sending
                    invites to add to your digital guestbook here.
                  </Alert>
                </Collapse>
              </Box>
              <Grid container>
                <GridItem item xs={2}>
                  &nbsp;
                </GridItem>
                <GridItem item xs={4}>
                  <ActionButton
                    label="View Sent Invites"
                    icon={<MarkChatReadIcon color="dark" />}
                    variant="contained"
                    size="oversized"
                    color="light"
                    onClick={() => {
                      console.log("Clicked Action");
                      executeScroll();
                    }}
                  />
                </GridItem>

                <GridItem item xs={4}>
                  <ActionButton
                    label="Guestbook Link
& QR Code"
                    icon={<ShareIcon color="dark" />}
                    variant="contained"
                    size="oversized"
                    color="light"
                    onClick={() => {
                      setShare(true);
                    }}
                  />
                </GridItem>
                <GridItem item xs={2}>
                  &nbsp;
                </GridItem>
              </Grid>
              <SendInvitations />
            </Stack>
          </Box>
        </Paper>
        <Box
          sx={{ minWidth: 350, maxWidth: 500, mx: "auto!important", p: 4 }}
          ref={ref}
        >
          <SentInvitesTable />
        </Box>
        {guestbook.slug && (
          <Dialog
            open={share}
            onClose={() => setShare(false)}
            aria-labelledby={`alert-dialog-title-${guestbook.slug}`}
            aria-describedby={`alert-dialog-description-${guestbook.slug}`}
          >
            <DialogTitle id={`alert-dialog-title-${guestbook.slug}`}>
              Share Your Digital Guestbook
            </DialogTitle>
            <DialogContent>
              <ShareBox slug={guestbook.slug} />
            </DialogContent>
          </Dialog>
        )}
      </InvitesContextProvider>
    </React.Fragment>
  );
}

/* eslint-disable no-unused-vars */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";
import { Button, Skeleton } from "@mui/material";
import ConfettiBox from "components/ConfettiBox";

// Analytics
import analytics from "../../analytics";

const Item = styled(Box)(() => ({
  textAlign: "center",
}));

export default function RespondLater() {
  const { loading, guestbook } = useGuestbookContext();
  const navigate = useNavigate();

  return (
    <>
      {loading && <LinearProgress />}
      <ConfettiBox pt={8}>
        <Box sx={{ maxWidth: "400px", mx: "auto!important" }}>
          <Stack spacing={2}>
            <Item>
              {loading ? (
                <Skeleton />
              ) : (
                <img
                  src={`${guestbook.image}`}
                  alt={guestbook.title}
                  loading="lazy"
                  width="100%"
                />
              )}
            </Item>
            <Item>
              <Typography variant="h1">See you soon!</Typography>
              <Typography variant="body1">
                We&apos;ll send you reminders as the big day approaches.
              </Typography>
            </Item>
            <Item>
              <Box m={6}>
                <Button
                  onClick={() => {
                    analytics.track(
                      "guestbook:finish_later:continue_responding_click"
                    );
                    navigate("../");
                  }}
                >
                  Continue responding?
                </Button>
              </Box>
            </Item>
          </Stack>
        </Box>
      </ConfettiBox>
    </>
  );
}

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

const useFileUpload = () => {
  let onStart = (task) => console.log(task);
  const setOnStart = (handler) => (onStart = handler);
  let onError = (error) => console.error(error);
  const setOnError = (handler) => (onError = handler);
  let onComplete = (url) => console.log(url);
  const setOnComplete = (handler) => (onComplete = handler);

  let onPercentChange = (percent) => console.log(percent);
  const setOnPercentChange = (handler) => (onPercentChange = handler);

  const handleUpload = (pathRoot, file, altName, onSuccess, onFailure) => {
    if (!file) {
      onError("Please select an image first!");
      onFailure && onFailure("Please select an image first!");
      return;
    }

    const storageRef = ref(storage, `${pathRoot}/${altName || file.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    onStart(uploadTask);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        onPercentChange(percent);
      },
      (err) => onError(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onComplete(url);
          onSuccess && onSuccess(url);
        });
      }
    );
  };

  const onHandle = (event, handler) => {
    switch (event) {
      case "start":
        setOnStart(handler);
        break;
      case "progress":
        setOnPercentChange(handler);
        break;
      case "error":
        setOnError(handler);
        break;
      case "complete":
        setOnComplete(handler);
        break;
      default:
        break;
    }

    return { on: onHandle };
  };

  return { on: onHandle, upload: handleUpload };
};

export const useMultiFileUpload = () => {
  let onStart = (task, index) => console.log(task, index);
  const setOnStart = (handler) => (onStart = handler);
  let onError = (error, index) => console.error(error, index);
  const setOnError = (handler) => (onError = handler);
  let onComplete = (url, index) => console.log(url, index);
  const setOnComplete = (handler) => (onComplete = handler);

  let onPercentChange = (percent) => console.log(percent);
  const setOnPercentChange = (handler) => (onPercentChange = handler);

  const handleUploadSingle = (
    pathRoot,
    file,
    altName,
    onSuccess,
    onFailure,
    onStartTask,
    onTaskPercentChange
  ) => {
    if (!file) {
      onFailure && onFailure("Please select an image first!");
      return;
    }

    const storageRef = ref(storage, `${pathRoot}/${altName || file.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    onStartTask && onStartTask(uploadTask);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        onTaskPercentChange && onTaskPercentChange(percent);
      },
      (err) => onFailure && onFailure(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onSuccess && onSuccess({ url, pathRoot, name: altName || file.name });
        });
      }
    );
  };

  const handleUploadMultiple = (files, onSuccess, onFailure) => {
    const count = files.length;
    let percentages = Array.from({ length: files.length }, () => 0);

    const promises = files.map(({ pathRoot, file, altName }, index) => {
      const promise = new Promise((resolve, reject) => {
        const onStartTask = (task) => onStart(task, index);
        const onTaskFailure = (error) => {
          onError(error, index);
          //Change percentage calcs.
          reject(error);
        };
        const onTaskPercentChange = (percent) => {
          percentages[index] = percent;
          const total = percentages.reduce(
            (partialSum, a) => partialSum + a,
            0
          );
          onPercentChange && onPercentChange(total / count);
        };
        const onTaskComplete = (url) => {
          onComplete(url, index);
          resolve(url);
        };

        return handleUploadSingle(
          pathRoot,
          file,
          altName,
          onTaskComplete,
          onTaskFailure,
          onStartTask,
          onTaskPercentChange
        );
      });
      return promise;
    });

    return Promise.all(promises)
      .then((results) => {
        onSuccess && onSuccess(results);
      })
      .catch((errors) => {
        onFailure && onFailure(errors);
      });
  };

  const onHandle = (event, handler) => {
    switch (event) {
      case "start":
        setOnStart(handler);
        break;
      case "progress":
        setOnPercentChange(handler);
        break;
      case "error":
        setOnError(handler);
        break;
      case "complete":
        setOnComplete(handler);
        break;
      default:
        break;
    }

    return { on: onHandle };
  };

  return { on: onHandle, upload: handleUploadMultiple };
};

export default useFileUpload;

import React, { useMemo } from "react";
import { debounce } from "@mui/material/utils";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import { useSetupContext } from "contexts/setup/SetupContextProvider";
import MKTypography from "components/MKTypography";

// Analytics
import analytics from "../../../analytics";

export default function Title() {
  const { data, setData } = useSetupContext();
  const { title } = data;

  const debouncedSetTitle = useMemo(
    () =>
      debounce((value) => {
        analytics.track("setup:title_changed", { value });
      }, 750),
    []
  );

  return (
    <>
      <MKBox component="section" py={4} maxWidth="400px">
        <MKTypography>
          What title would you like to give your Digital Guestbook?
        </MKTypography>
        <MKInput
          label="Guestbook Title"
          fullWidth
          variant="standard"
          placeholder="e.g. Jake and Ginny's Joyous Wedding"
          value={title || ""}
          onChange={(e) => {
            setData({ title: e.target.value });
            debouncedSetTitle(e.target.value);
          }}
          onFocus={() => analytics.track("setup:title_focus", { value: title })}
          onBlur={() => {
            analytics.track("setup:title_blur", { value: title });
          }}
        />
        <MKTypography variant="caption">
          This will be seen by your guests in invitations & reminders, as well
          as when they click the link to sign your Digital Guestbook. We
          recommend adding either &apos;Guestbook&apos; or &apos;Digital
          Guestbook&apos; to the end of your title for best results.
        </MKTypography>
      </MKBox>
    </>
  );
}

import * as React from "react";
//import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DiamondIcon from "@mui/icons-material/Diamond";

import seperator from "assets/images/shapes/heading-seperator.svg";

import PackageCards from "../PackageCards";
import GridItem from "components/Grid/GridItem";
import MKButton from "components/MKButton";
import { upgradePaths } from "utils/upgradeInfo";
import { useFinalVideoContext } from "contexts/guestbook/finalVideo/Context";
import { useNavigate } from "react-router";

export default function GoldStartFinal() {
  const { progress, save } = useFinalVideoContext();
  const navigate = useNavigate();

  const onSave = () => {
    save().then(() => {
      navigate("../", { relative: "path" });
    });
  };

  return (
    <React.Fragment>
      <Stack direction="column" spacing={2} mb={2}>
        <Box>
          <Box textAlign="center">
            <Typography
              align="center"
              variant="h2"
              component="h2"
              sx={{ p: 2, pb: 0 }}
            >
              Final Video
            </Typography>
            <img src={seperator} />
          </Box>
        </Box>
        <Typography variant="body2" gutterBottom>
          Congrats! You’ve collected lovely messages and memories from your
          loved ones. Now, with your Gold package, let a pro help! We’ll combine
          your content into a timeless montage video you’ll cherish for years.
          Submit for your final video below and let the magic begin!
        </Typography>
        <Typography variant="body2" gutterBottom>
          Want more? Upgrade to Diamond to receive:
        </Typography>
        <Typography
          variant="body2"
          component="ul"
          sx={{ mx: "2rem!important", fontWeight: "bold" }}
        >
          <li>Full Customizability</li>
          <li>2 Rounds of Revisions</li>
          <li>Choice of Music Style</li>
          <li>Add Additional Media</li>
          <li>Specify Order of Responses</li>
        </Typography>
        {progress && <LinearProgress variant="determinate" value={progress} />}
        <Grid container spacing={2}>
          <GridItem item xs={6}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={onSave}
              size="large"
            >
              <CheckCircleIcon />
              &nbsp;Submit for Final Video
            </MKButton>
          </GridItem>
          <GridItem item xs={6}>
            <MKButton
              variant="gradient"
              color="diamond"
              href={upgradePaths.gold.diamond.url}
              size="large"
              target="blank"
            >
              <DiamondIcon />
              &nbsp;Upgrade to Diamond
            </MKButton>
          </GridItem>
          <GridItem item xs={2}></GridItem>
        </Grid>
        <PackageCards current={"gold"} show="upgrades" />
      </Stack>
    </React.Fragment>
  );
}

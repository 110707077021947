import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

export default function AddContactForm({ onAddContact }) {
  const [useEmail, setUseEmail] = React.useState(true);
  const [name, setName] = React.useState("");
  const [contact, setContact] = React.useState("");

  const addContact = () => {
    let newContact = { name, email: null, phone: null, state: "PENDING" };
    if (useEmail) {
      newContact.email = contact;
    } else {
      newContact.phone = contact;
    }
    onAddContact(newContact).then(() => {
      setName("");
      setContact("");
      setUseEmail(true);
    });
  };

  const handleMethodChange = (event, contactMethod) => {
    setUseEmail(contactMethod === "email");
  };
  return (
    <Stack spacing={2}>
      <FormControl fullWidth>
        <InputLabel htmlFor="contactName">Name</InputLabel>
        <OutlinedInput
          id="contactName"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="contact">
          {useEmail ? "Email" : "Phone"}
        </InputLabel>
        <OutlinedInput
          id="contact"
          label={useEmail ? "Email" : "Phone"}
          type={useEmail ? "email" : "phone"}
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <ToggleButtonGroup
                value={useEmail ? "email" : "phone"}
                exclusive
                onChange={handleMethodChange}
              >
                <ToggleButton value="email" aria-label="email">
                  <EmailIcon />
                </ToggleButton>
                <ToggleButton value="phone" aria-label="phone">
                  <PhoneIphoneIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </InputAdornment>
          }
        />
      </FormControl>
      <MKBox sx={{ textAlign: "right" }}>
        <MKButton variant="gradient" color="dark" onClick={addContact}>
          Add Contact
        </MKButton>
      </MKBox>
    </Stack>
  );
}

AddContactForm.propTypes = {
  onAddContact: PropTypes.func.isRequired,
};

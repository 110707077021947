import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import bytesToMB from "utils/bytesToMB";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const FileDownloaderProps = {
  downloadQueueFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDownloadQueueFiles: PropTypes.func.isRequired,
};

const SingleItemDownloadProps = {
  setDownloadQueueFiles: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

const TextDownloaderProps = {
  downloadQueueText: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDownloadQueueText: PropTypes.func.isRequired,
};

const SingleTextDownloadProps = {
  setDownloadQueueText: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

const FileDownloader = ({ downloadQueueFiles, setDownloadQueueFiles }) => {
  if (!downloadQueueFiles?.length) return null;

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      {downloadQueueFiles?.map((downloadUrl, index) => (
        <SingleItemDownload
          key={index}
          setDownloadQueueFiles={setDownloadQueueFiles}
          downloadUrl={downloadUrl}
        />
      ))}
    </List>
  );
};
FileDownloader.propTypes = FileDownloaderProps;

const SingleItemDownload = ({ setDownloadQueueFiles, downloadUrl }) => {
  const isDownloadingStartRef = useRef(true);
  const { enqueueSnackbar: toast, closeSnackbar } = useSnackbar();
  const action = (snackbarId) => (
    <>
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CancelIcon />
      </IconButton>
    </>
  );

  const [progressInfo, setProgressInfo] = useState({
    fileName: decodeURIComponent(downloadUrl).split("/").pop().split("?")[0],
    progress: 0,
    isCompleted: false,
    total: 0,
    loaded: 0,
  });

  const CancelToken = axios.CancelToken;
  const cancelSource = useRef(null);

  useEffect(() => {
    if (isDownloadingStartRef?.current) {
      const startDownload = async () => {
        cancelSource.current = CancelToken.source();

        await axios
          .get(downloadUrl, {
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const progress = Math.floor((loaded * 100) / total);
              setProgressInfo((info) => ({
                ...info,
                progress,
                loaded,
                total,
              }));
            },
            cancelToken: cancelSource.current.token,
          })
          .then(function (response) {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", progressInfo?.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            setProgressInfo((info) => ({ ...info, isCompleted: true }));

            toast(`${progressInfo?.fileName} successfully downloaded.`, {
              variant: "success",
              autoHideDuration: 3000,
              action,
            });
          })
          .catch((err) => {
            console.err(err);
            setProgressInfo((info) => ({ ...info, isCanceled: true }));
            toast(`${progressInfo?.fileName} could not be downloaded.`, {
              variant: "error",
              action,
            });
          })
          .finally(() => {
            setDownloadQueueFiles((prevFiles) =>
              prevFiles?.filter((url) => url !== downloadUrl)
            );
          });
      };

      startDownload();
    }

    return () => {
      isDownloadingStartRef.current = false;
    };
  }, []);

  const onDownloadCancel = () => {
    cancelSource.current.cancel(
      `${progressInfo?.fileName} downloading cancelled.`
    );
  };

  return (
    <ListItem key={progressInfo?.fileName}>
      <ListItemIcon>
        {progressInfo?.isCompleted ? (
          <CloudDoneIcon color="success" />
        ) : progressInfo?.loaded ? (
          <CloudDownloadIcon color="info" />
        ) : (
          <CloudQueueIcon color="info" />
        )}
      </ListItemIcon>
      <ListItemText
        primary={progressInfo?.fileName}
        secondary={
          <>
            <span className="text-red-600">
              {bytesToMB(progressInfo.loaded)}
            </span>{" "}
            of {bytesToMB(progressInfo.total)}
            MB
          </>
        }
        primaryTypographyProps={{
          fontSize: "0.85rem",
          fontWeight: "medium",
          letterSpacing: 0,
        }}
        secondaryTypographyProps={{
          fontSize: "0.65rem",
          fontWeight: "medium",
          letterSpacing: 0,
        }}
        secondaryAction={
          !progressInfo?.isCompleted &&
          progressInfo?.progress < 100 && (
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={onDownloadCancel}
            >
              <CancelIcon />
            </IconButton>
          )
        }
      />
    </ListItem>
  );
};
SingleItemDownload.propTypes = SingleItemDownloadProps;

export const TextDownloader = memo(
  ({ downloadQueueText, setDownloadQueueText }) => {
    if (!downloadQueueText?.length) return null;

    return (
      <div className="absolute right-0 bottom-0 w-auto mr-2 bg-gray-300 p-2 z-50 rounded-sm">
        <div className="w-full text-xs py-1">
          {downloadQueueText?.map(({ content, filename }, index) => (
            <SingleTextDownload
              key={index}
              setDownloadQueueText={setDownloadQueueText}
              content={content}
              filename={filename}
            />
          ))}
        </div>
      </div>
    );
  }
);
TextDownloader.propTypes = TextDownloaderProps;

const SingleTextDownload = ({ setDownloadQueueText, content, filename }) => {
  const isDownloadingStartRef = useRef(true);
  const { enqueueSnackbar: toast, closeSnackbar } = useSnackbar();
  const action = (snackbarId) => (
    <>
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CancelIcon />
      </IconButton>
    </>
  );

  const [progressInfo, setProgressInfo] = useState({
    fileName: filename,
    progress: 0,
    isCompleted: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    if (isDownloadingStartRef?.current) {
      const startDownload = async () => {
        const url = window.URL.createObjectURL(
          new Blob([content], { type: "text/plain" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", progressInfo?.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        setProgressInfo((info) => ({ ...info, isCompleted: true }));

        toast(`${progressInfo?.fileName} successfully downloaded.`, {
          variant: "success",
          autoHideDuration: 3000,
          action,
        });

        setDownloadQueueText((prevFiles) =>
          prevFiles?.filter(
            ({ filename: name }) => name !== progressInfo?.fileName
          )
        );
      };

      startDownload();
    }

    return () => {
      isDownloadingStartRef.current = false;
    };
  }, []);

  return (
    <div>
      <span>icon</span>
      <span>
        {!progressInfo?.isCompleted && progressInfo?.loaded <= 0 ? (
          <span>{progressInfo?.fileName} waiting for download.</span>
        ) : (
          <span>
            {progressInfo?.fileName} - (
            <span>
              <span className="text-red-600">
                {bytesToMB(progressInfo.loaded)}
              </span>{" "}
              of {bytesToMB(progressInfo.total)}
              MB)
            </span>
          </span>
        )}
      </span>
    </div>
  );
};
SingleTextDownload.propTypes = SingleTextDownloadProps;

export default memo(FileDownloader);

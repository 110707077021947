import Message from "./Message";
import Prompts from "./Prompts";
import Photo from "./Photo";
import SelectDate from "./SelectDate";
import Title from "./Title";

const steps = [
  {
    title: "Title",
    component: <Title />,
    validate: (data) => {
      return !!data.title;
    },
  },
  {
    title: "Photo",
    component: <Photo />,
    validate: (data) => {
      return !!data.image || data.imageFile;
    },
  },
  {
    title: "Message",
    component: <Message />,
    validate: (data) => {
      return !!data.message; //todo: validate the date.
    },
  },
  {
    title: "Prompts",
    component: <Prompts />,
    validate: (data) => {
      return (
        !!data.prompts &&
        !!data.prompts.length &&
        [...data.prompts].every((prompt) => !!prompt.text)
      );
    },
  },
  {
    title: "Date",
    component: <SelectDate />,
    validate: (data) => {
      return !!data.endDate; //todo: validate the date.
    },
  },
];

export default steps;

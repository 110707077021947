export const upgradePaths = {
  silver: {
    gold: {
      url: "https://quilted.io/cart/42649012535454:1",
      price: "$120",
    },
    diamond: {
      url: "https://quilted.io/cart/42649012600990:1",
      price: "$290",
    },
  },
  gold: {
    diamond: {
      url: "https://quilted.io/cart/42649012568222:1",
      price: "$50",
    },
  },
};

export default function getUpgradeInfo(current) {
  if (["silver", "gold"].includes(current)) {
    return upgradePaths[current];
  } else {
    throw new Error("Cannot upgrade from " + current);
  }
}

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
const GridItem = styled(Grid)(() => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

export default GridItem;

// TODO: Separate out components
import React from "react";

// Material UI Components
import Typography from "@mui/material/Typography";
import { useSetupContext } from "contexts/setup/SetupContextProvider";
import { Box, Step, StepButton, StepContent, Stepper } from "@mui/material";
import MKButton from "components/MKButton";
import { Navigate } from "react-router-dom";
import MKProgress from "components/MKProgress";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";
import analytics from "../../../analytics";

export default function Setup() {
  const context = useSetupContext();
  const {
    currentStep,
    steps,
    next,
    previous,
    data,
    goto,
    saving,
    progress,
    error,
  } = context;

  const handleBack = () => {
    analytics.track("setup:back_click", {
      currentStep: steps[currentStep].title,
      isValid: steps[currentStep].isValid,
    });
    previous();
  };
  const handleNext = () => {
    analytics.track("setup:continue_click", {
      currentStep: steps[currentStep].title,
      isValid: !!steps[currentStep].isValid,
    });
    next();
  };
  const handleStep = (i) => goto(i);
  const allValid = () => {
    return steps.every((step) => step.isValid);
  };
  const isLastStep = (index) => {
    return index === steps.length - 1;
  };

  return (
    <>
      <Stepper activeStep={currentStep} orientation="vertical">
        {steps.map((step, index) => {
          const labelProps = {};
          const isValid = (valid) => {
            return valid === undefined || !!valid;
          };
          if (!isValid(step.isValid)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                * required
              </Typography>
            );

            labelProps.error = "true";
          }
          return (
            <Step key={step.title}>
              <StepButton
                onClick={() => {
                  analytics.track("setup:step_click", {
                    currentStep: steps[currentStep].title,
                    goto: step.title,
                  });
                  handleStep(index);
                }}
                optional={
                  isLastStep(index) ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
                {...labelProps}
              >
                {step.title}
              </StepButton>
              <StepContent>
                <Box sx={{ mb: 2 }}>
                  {step.component}
                  {saving && (
                    <MKProgress value={progress} label color="primary" />
                  )}
                  {error && (
                    <MKBox mb={2}>
                      <MKAlert color="error" dismissible>
                        <MKBox>
                          <MKTypography variant="h4" color="white" mb={1}>
                            Oh No!
                          </MKTypography>
                          <MKTypography variant="body2" color="white">
                            {error}
                          </MKTypography>
                        </MKBox>
                      </MKAlert>
                    </MKBox>
                  )}
                  <MKButton
                    color="black"
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={(isLastStep(index) && !allValid()) || !!saving}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </MKButton>
                  <MKButton
                    disabled={index === 0 || !!saving}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </MKButton>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      {currentStep === steps.length && <Navigate to="complete" state={data} />}
    </>
  );
}

import React, { useState } from "react";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";

import MKProgress from "components/MKProgress";
import ResponseButton from "../ResponseButton";

import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";

export default function TextResponse({ previousValue, onCancel, onSave }) {
  const { saving, progress } = useGuestbookContext();
  const [message, setMessage] = useState();

  const handleSave = () => {
    onSave && onSave({ content: message, responseType: "text" });
  };

  return (
    <Box>
      <TextField
        id="message"
        label="Message"
        multiline
        maxRows={10}
        minRows={4}
        fullWidth
        value={previousValue || message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={!!previousValue}
      />
      {saving && <MKProgress value={progress} color="primary" />}
      <Stack direction="row">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <ResponseButton
            color="error"
            icon={<CancelIcon />}
            label="Delete"
            variant="text"
            size="large"
            onClick={onCancel}
            disabled={saving}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {!previousValue && (
            <ResponseButton
              color="success"
              icon={<CheckIcon />}
              label="Save"
              variant="text"
              size="large"
              onClick={handleSave}
              disabled={!message || saving}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}

TextResponse.propTypes = {
  previousValue: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

import * as React from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";

import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKSnackbar from "components/MKSnackbar";
import QRCodeDownload from "components/QRCode";

import useClickToCopy from "hooks/useClickToCopy";
import buildURL from "utils/buildUrl";

export default function ShareBox({ slug }) {
  const url = buildURL({ slug: `${slug}/guestbook` });
  const qrUrl = buildURL({ slug: `qr/${slug}/guestbook` });

  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const toggleSnackbar = () => setShow(!show);
  const onCopy = () => {
    setShow(true);
  };
  const handler = useClickToCopy(url, onCopy);

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <MKTypography variant="subtitle1" sx={{ textAlign: "center" }}>
          Guestbook Link:
        </MKTypography>
        <MKInput readOnly={true} value={url} />

        <MKButton color="dark" {...handler}>
          Copy Url
        </MKButton>

        <MKButton color="dark" variant="outlined" onClick={() => setOpen(true)}>
          Download Printable QR Code
        </MKButton>
      </Stack>
      <MKSnackbar
        color="info"
        icon="notifications"
        title="Guestbook Link"
        content="Your guestbook link has been copied to your clipboard."
        open={show}
        close={toggleSnackbar}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="qr-code-title"
      >
        <QRCodeDownload url={qrUrl} onDownload={() => setOpen(false)} />
      </Modal>
    </React.Fragment>
  );
}

ShareBox.propTypes = {
  slug: PropTypes.string.isRequired,
};

import MKBox from "components/MKBox";
import PromptsList from "./Prompts/PromptsList";
import MKTypography from "components/MKTypography";

export default function Prompts() {
  //https://codesandbox.io/s/blissful-beaver-vv5jo?file=/src/Card.jsx
  //
  return (
    <MKBox component="section" py={4} maxWidth="400px">
      <MKTypography>
        What messages, photos or videos would you like guests to share?
      </MKTypography>
      <MKTypography variant="subtitle2">Tap to edit prompt</MKTypography>
      <PromptsList />
    </MKBox>
  );
}

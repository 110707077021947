import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SignatureAvatar from "./SignatureAvatar";

function TextResponseCard({ response }) {
  const [isOpen, setOpen] = React.useState();
  return (
    <>
      <CardContent>
        <Typography variant="body2" noWrap={!isOpen}>
          {response.content}
        </Typography>
      </CardContent>
      {response.content.length > 36 && (
        <CardActions>
          <Button
            size="small"
            onClick={() => setOpen((open) => !open)}
            sx={{ mx: "auto" }}
          >
            {isOpen ? "Less" : "More"}
          </Button>
        </CardActions>
      )}
    </>
  );
}

TextResponseCard.propTypes = {
  response: PropTypes.object.isRequired,
};

function MediaResponseCard({ response, value }) {
  return (
    <CardMedia
      component={value === "photo" ? "img" : value}
      controls
      src={response.content.url}
    />
  );
}

MediaResponseCard.propTypes = {
  response: PropTypes.object.isRequired,
  value: PropTypes.oneOf(["video", "photo"]).isRequired,
};

const Switch = (props) => {
  const { test, children } = props;
  // filter out only children with a matching prop
  const child = children.find((child) => {
    return child.props.value === test;
  });
  return <>{child}</>;
};
Switch.propTypes = {
  test: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default function ResponseCard({ response, signature }) {
  console.log("render ResponseCard", response, signature);
  return (
    <Card sx={{ width: "100%", maxWidth: 345, mx: "auto" }}>
      <CardHeader
        avatar={<SignatureAvatar signature={signature} />}
        title={signature.signature}
      />
      <Switch test={response.responseType}>
        <TextResponseCard response={response} value="text" />
        <MediaResponseCard response={response} value="photo" />
        <MediaResponseCard response={response} value="video" />
      </Switch>
    </Card>
  );
}

ResponseCard.propTypes = {
  signature: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
};

class Response {
  constructor(
    id,
    { uid, promptId, signatureId, responseType, content, created }
  ) {
    this.id = id;
    this.uid = uid;
    this.promptId = promptId;
    this.signatureId = signatureId;
    this.responseType = responseType;
    this.content = content;
    this.created = created;
  }
  toString() {
    return this.content;
  }
}

export class TextResponse extends Response {
  constructor(id, { uid, promptId, signatureId, content, created }) {
    super(id, {
      uid,
      promptId,
      signatureId,
      content,
      created,
      responseType: "text",
    });
  }
}
export class VideoResponse extends Response {
  constructor(id, { uid, promptId, signatureId, content, created }) {
    super(id, {
      uid,
      promptId,
      signatureId,
      content,
      created,
      responseType: "video",
    });
  }
}
export class PhotoResponse extends Response {
  constructor(id, { uid, promptId, signatureId, content, created }) {
    super(id, {
      uid,
      promptId,
      signatureId,
      content,
      created,
      responseType: "photo",
    });
  }
}

// Firestore data converter
const responseConverter = {
  toFirestore: ({
    uid,
    promptId,
    signatureId,
    responseType,
    content,
    created,
  }) => {
    return { uid, promptId, signatureId, responseType, content, created };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const id = snapshot.id;
    return new Response(id, data);
  },
};

export default responseConverter;

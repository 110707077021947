import { Outlet } from "react-router-dom";

import { useAuthContext } from "contexts/AuthContextProvider";
import { useEffect } from "react";
import { LinearProgress } from "@mui/material";

const AnonymousRoutes = () => {
  const { user, signInAnon } = useAuthContext();
  useEffect(() => {
    if (user !== undefined && !user) signInAnon();
  }, [user]);
  return user ? (
    <>
      <Outlet />
    </>
  ) : (
    <LinearProgress />
  );
};

// Typechecking props for the BasicLayout
AnonymousRoutes.propTypes = {};

export default AnonymousRoutes;

function mapAuthErrorCodeToMessage(authCode) {
  switch (authCode) {
    case "auth/app-deleted":
      return "There was a problem connecting to the application.";
    case "auth/app-not-authorized":
      return "The app identified by the domain is not authorized.";
    case "auth/argument-error":
      return "There was an error with your account, please try again or contact support.";
    case "auth/invalid-api-key":
      return "There was a problem connecting to the application.";
    case "auth/invalid-user-token":
      return "Your session has expired or is no longer valid. Please first sign out, then sign in again.";
    case "auth/invalid-tenant-id":
      return "There was a problem connecting to the application.";
    case "auth/network-request-failed":
      return "A network error (such as timeout, interrupted connection or unreachable host) has occurred. Please check your internet connection and try again.";
    case "auth/operation-not-allowed":
      return "There was a problem connecting to the application.";
    case "auth/requires-recent-login":
      return "Your session has expired or is no longer valid. Please first sign out, then sign in again.";
    case "auth/too-many-requests":
      return "There was a problem connecting to the application. Please trying again after a few moments.";
    case "auth/unauthorized-domain":
      return "There was a problem connecting to the application.";
    case "auth/user-disabled":
      return "There was an error with your account, please contact support.";
    case "auth/user-not-found":
      return "There was an issue locating your account, please check your email and try again or contact support.";
    case "auth/user-token-expired":
      return "Your session has expired or is no longer valid. Please first sign out, then sign in again.";
    case "auth/web-storage-unsupported":
      return "Your browser does not support web storage or has been disabled. Please enable web storage or use a current browser.";
    case "auth/email-already-in-use":
      return "This email address is already registered. Please login using this email address.";
    case "auth/account-exists-with-different-credential":
      return "This email address is already registered using a different login provider. Please login using this email address.";
    case "auth/wrong-password":
      return "Incorrect password. Please try again or contact support.";
    case "auth/invalid-password":
      return "Password provided is not corrected.";
    case "auth/weak-password":
      return "Your password must be at least 6 characters.";

    default:
      return "An unknown error occured. Please try again or contact support.";
  }
}

export default mapAuthErrorCodeToMessage;

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cropper from "react-cropper";
import Skeleton from "@mui/material/Skeleton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import FlipIcon from "@mui/icons-material/Flip";
//import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import Crop54 from "@mui/icons-material/Crop54";

import MKButton from "components/MKButton";

import "cropperjs/dist/cropper.css";

const defaultAspectRatio = 4 / 3; // 16 / 9;
export default function ImageCropper({ src, getCroppedData }) {
  const cropperRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);

  useEffect(() => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper?.setAspectRatio(aspectRatio);
  }, [aspectRatio, cropperRef?.current]);

  const handleAspectRatio = (_event, newAspectRatio) => {
    if (newAspectRatio) {
      setAspectRatio(newAspectRatio);
    }
  };

  const handleClick = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const img = cropper.getCroppedCanvas().toDataURL();
    getCroppedData(img);
  };

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
  };

  const flip = (type) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (type === "h") {
      cropper.scaleX(scaleX === 1 ? -1 : 1);
      setScaleX(scaleX === 1 ? -1 : 1);
    } else {
      cropper.scaleY(scaleY === 1 ? -1 : 1);
      setScaleY(scaleY === 1 ? -1 : 1);
    }
  };

  return (
    <>
      {loading && (
        <Skeleton variant="rectangular" width={"100%"} height={400} />
      )}
      <Box display={"flex"} justifyContent={"space-between"} mb={1}>
        <ButtonGroup disableElevation variant="contained" color="black">
          <IconButton onClick={rotate} aria-label="Rotate">
            <CropRotateIcon />
          </IconButton>
          <IconButton onClick={() => flip("h")} aria-label="Flip Horizontal">
            <FlipIcon />
          </IconButton>
          <IconButton onClick={() => flip("v")} aria-label="Flip Verticle">
            <FlipIcon sx={{ transform: "rotate(90deg)" }} />
          </IconButton>
        </ButtonGroup>
        <ToggleButtonGroup
          value={aspectRatio}
          onChange={handleAspectRatio}
          exclusive
          aria-label="aspect ratio"
          color="black"
        >
          <ToggleButton value={4 / 3} aria-label="4:3">
            <Crop32Icon />
          </ToggleButton>
          <ToggleButton value={5 / 4} aria-label="5:4">
            <Crop54 />
          </ToggleButton>
          <ToggleButton value={16 / 9} aria-label="16:9">
            <Crop169Icon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Cropper
        src={src}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={aspectRatio || 16 / 9}
        aspectRatio={aspectRatio}
        guides={true}
        ready={() => {
          setLoading(false);
        }}
        ref={cropperRef}
      />
      <MKButton
        color="black"
        sx={{
          float: "right",
          mt: 1,
        }}
        onClick={handleClick}
        autoFocus
        variant="contained"
      >
        Crop
      </MKButton>
    </>
  );
}

ImageCropper.propTypes = {
  src: PropTypes.string.isRequired,
  getCroppedData: PropTypes.func.isRequired,
};

/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Kit 2 PRO React Helper Function
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { black, white, gradients, transparent } = colors;
const { borderWidth, borderRadius } = borders;
const { md, colored } = boxShadows;

export default {
  variants: [
    {
      props: { variant: "primary" },
      style: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minWidth: 0,
        wordWrap: "break-word",
        backgroundColor: white.main,
        backgroundClip: "border-box",
        border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
        borderRadius: borderRadius.xl,
        boxShadow: md,
        overflow: "visible",
      },
    },
    {
      props: { variant: "primary", color: "primary" },
      style: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minWidth: 0,
        wordWrap: "break-word",
        backgroundColor: white.main,
        backgroundClip: "border-box",
        border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
        overflow: "visible",
        background: linearGradient(
          gradients.primary.main,
          gradients.primary.state
        ),
        color: white.main,
        padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
        borderRadius: borderRadius.lg,
        boxShadow: colored.info,

        "&.MuiPaper-root": {
          backgroundColor: transparent.main,
        },
      },
    },
  ],
  styleOverrides: {
    root: {},
  },
};

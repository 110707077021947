import * as React from "react";
import { Outlet } from "react-router-dom";

// Material UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { GuestbookContextProvider } from "contexts/guestbook/GuestbookContextProvider";
import logo from "assets/images/logos/white-logos/logo-quilted-white.png";

function GuestbookPublicLayout() {
  return (
    <>
      <GuestbookContextProvider>
        <AppBar position="static" color="black">
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <img src={logo} alt="Quilted" />
            </Box>
          </Toolbar>
        </AppBar>
        <Paper square sx={{ pb: "50px" }}>
          <Outlet />
        </Paper>
      </GuestbookContextProvider>
    </>
  );
}

export default GuestbookPublicLayout;

/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// themes
import theme from "assets/theme";

// routes
import routes from "routes";
import AnonymousRoutes from "utils/Router/AnonymousRoutes";
import ProtectedRoutes from "utils/Router/ProtectedRoutes";
import { QRCodeGuestbookRedirect } from "utils/Router/QRCodeGuestbookRedirect";

// layouts
import DashboardLayout from "layouts/pages/dashboard";
import SetupLayout from "layouts/pages/setup";
import GuestbookPublicLayout from "layouts/pages/guestbook/public";

// pages
import SignIn from "pages/authentication/EmailSignIn";
import SignOut from "pages/authentication/SignOut";
import DashboardPage from "pages/dashboard";
import GuestbookDashboardPage from "pages/dashboard/Guestbook";
import SetupPage from "pages/setup";
import SetupCompletePage from "pages/setup/Complete";
import Guestbook from "pages/guestbook";
import Finished from "pages/guestbook/Finished";

// contexts
import { AuthContextProvider } from "contexts/AuthContextProvider";
import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material";
import GuestbookInvitationsPage from "pages/dashboard/Invitations";
import GuestbookSettingssPage from "pages/dashboard/Settings";
import SilverUpgradePage from "pages/dashboard/Silver";
import StartFinalPage from "pages/dashboard/StartFinal";
import FinalRevisionsPage from "pages/dashboard/Revisions";
import { SnackbarProvider } from "notistack";
import RespondLater from "pages/guestbook/Later";
import { usePageAnalytics } from "analytics/usePageAnalytics";

export default function App() {
  const { pathname } = useLocation();
  usePageAnalytics();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, isPrivate = false) =>
    allRoutes
      .filter((route) => isPrivate === !!route.isPrivate)
      .map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key || route.name}
            />
          );
        }

        return null;
      });

  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          preventDuplicate={true}
        >
          <Routes>
            <Route
              element={
                <>
                  <Box mb="50px">
                    <Outlet />
                  </Box>
                  <AppBar
                    color="black"
                    position="fixed"
                    sx={{ top: "auto", bottom: 0 }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                        <Typography
                          align="center"
                          variant="caption"
                          color="white.main"
                        >
                          &copy; 2023,
                        </Typography>
                        &nbsp;
                        <Link
                          variant="caption"
                          href="//quilted.io"
                          color="white.main"
                          sx={{ fontWeight: "bold" }}
                        >
                          Quilted
                        </Link>
                      </Box>
                    </Toolbar>
                  </AppBar>
                </>
              }
            >
              <Route path="/sign-in" element={<SignIn />} />
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route element={<ProtectedRoutes />}>
                <Route element={<DashboardLayout />}>
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route
                    path="/dashboard/guestbook/:id"
                    element={<GuestbookDashboardPage />}
                  />
                  <Route
                    path="/dashboard/guestbook/:id/invites"
                    element={<GuestbookInvitationsPage />}
                  />
                  <Route
                    path="/dashboard/guestbook/:id/settings"
                    element={<GuestbookSettingssPage />}
                  />
                  <Route
                    path="/dashboard/guestbook/:id/silver"
                    element={<SilverUpgradePage />}
                  />
                  <Route
                    path="/dashboard/guestbook/:id/start-final"
                    element={<StartFinalPage />}
                  />
                  <Route
                    path="/dashboard/guestbook/:id/revision-request"
                    element={<FinalRevisionsPage />}
                  />
                </Route>
                <Route element={<SetupLayout />}>
                  <Route path="/setup" element={<SetupPage />} />
                  <Route
                    path="/setup/complete"
                    element={<SetupCompletePage />}
                  />
                </Route>
                <Route path="/sign-out" element={<SignOut />} />
              </Route>
              <Route
                path="/qr/:slug/guestbook"
                element={<QRCodeGuestbookRedirect />}
              />
              <Route element={<AnonymousRoutes />}>
                <Route
                  path="/:slug/guestbook"
                  element={<GuestbookPublicLayout />}
                >
                  <Route index element={<Guestbook />} />
                  <Route path="finish-later" element={<RespondLater />} />
                  <Route path="finished" element={<Finished />} />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </SnackbarProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

import { DEFAULT_REMINDERS } from "contexts/shared/constants";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";

class Guestbook {
  constructor(
    id,
    {
      uid,
      email,
      title,
      image,
      imageFile,
      message,
      prompts,
      endDate,
      slug,
      reminders,
      tier,
      finalVideo,
    }
  ) {
    this.id = id;
    this.uid = uid;
    this.email = email;
    this.title = title;
    this.image = image;
    this.imageFile = imageFile;
    this.message = message;
    this.prompts = prompts;
    this.reminders = reminders || DEFAULT_REMINDERS;
    this.tier = tier;
    if (endDate instanceof Timestamp) {
      this.endDate = dayjs(endDate.toDate());
    } else if (endDate instanceof Date) {
      this.endDate = dayjs(endDate);
    }
    this.slug = slug;
    this.finalVideo = finalVideo;
    if (finalVideo) {
      if (finalVideo.requested instanceof Timestamp) {
        this.finalVideo.requested = dayjs(finalVideo.requested.toDate());
      } else if (finalVideo.requested instanceof Date) {
        this.finalVideo.requested = dayjs(finalVideo.requested);
      }
    }
  }
  toString() {
    return this.title;
  }
}

// Firestore data converter
const guestbookConverter = {
  toFirestore: ({
    uid,
    email,
    title,
    image,
    message,
    prompts,
    endDate,
    slug,
    reminders,
  }) => {
    let endDateEval = endDate;
    if (endDate instanceof Timestamp) {
      endDateEval = endDate.toDate();
    } else if (endDate instanceof Date) {
      endDateEval = endDate;
    } else {
      endDateEval = endDate.toDate();
    }
    return {
      uid,
      email,
      title,
      image,
      message,
      prompts,
      endDate: endDateEval,
      slug,
      reminders,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const id = snapshot.id;
    return new Guestbook(id, data);
  },
};

export default guestbookConverter;

/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

if (location.hostname === "localhost") {
  console.warn("Using Firebase Emulators!");
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5002);
  connectStorageEmulator(storage, "localhost", 9199);
}

export const setTestData = async () => {
  if (!auth.currentUser) return;
  const userId = auth.currentUser.uid;

  import("./firebase/__testData/testData")
    .then(({ default: testData }) => {
      // Add firestore documents
      Object.keys(testData.documents).forEach((key) => {
        testData.documents[key].forEach(async (document) => {
          const docKeys = Object.keys(document);
          const docHasId = !!docKeys.filter((docKey) => docKey === "_id")
            .length;
          docHasId
            ? await setDoc(doc(db, key, document._id), {
                ...document,
                userId,
                created: Timestamp.fromDate(document.created),
              })
            : await addDoc(collection(db, key), {
                ...document,
                userId,
                created: Timestamp.fromDate(document.created),
              });
        });
      });

      // Add objects to storage
      if (testData.images) {
        import("./firebase/__testData/images")
          .then(({ testImages }) => {
            testData.images?.forEach(async (image) => {
              const pathElements = image.split("/");
              const fileName =
                pathElements[pathElements.length - 1].split(".")[0];
              const file = testImages[fileName];
              await uploadString(ref(storage, image), file, "data_url");
            });
          })
          .catch((err) => {
            // Handle failure
            console.error("Could not load test images.", err);
          });
      }
    })
    .catch((err) => {
      // Handle failure
      console.error("Could not load test data.", err);
    });
};

export const cleanObject = (object) => {
  const remove = [];
  Object.keys(object).forEach((key) => {
    if (object[key] === undefined) remove.push(key);
  });
  remove.forEach((key) => delete object[key]);
  return object;
};

export { app, db, auth, storage, functions };

// import update from "immutability-helper";
import { SAVING } from "contexts/shared/constants";
import {
  SET_INVITATION_SETTINGS,
  SET_GUESTBOOK,
  SET_LOADING,
} from "./constants";

export const defaultInvitesState = {
  loading: true,
  guestbookId: null,
  guestbook: null,
  settings: null,
  saving: false,
};

export const reducer = (state = defaultInvitesState, action) => {
  const { type, payload } = action;
  console.log(action);
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_GUESTBOOK:
      return {
        ...state,
        guestbook: payload,
      };
    case SET_INVITATION_SETTINGS:
      return {
        ...state,
        settings: payload,
      };
    case SAVING: {
      return {
        ...state,
        saving: !!(payload > 0 && payload < 100),
        progress: payload,
      };
    }

    default:
      return state;
  }
};

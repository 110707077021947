import * as React from "react";
import { collection } from "firebase/firestore";
import { db } from "../../../firebase";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PendingIcon from "@mui/icons-material/Pending";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SendIcon from "@mui/icons-material/Send";
import MKTypography from "components/MKTypography";
import { useInvitesContext } from "contexts/guestbook/invites/InvitesContextProvider";
import { buildInvitationsCollectionName } from "contexts/guestbook/invites/InvitesContextProvider";
import invitationConverter from "../../../firebase/converters/invitation";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function SentInvitesTable() {
  const { loading, guestbookId } = useInvitesContext();
  const collectionName = buildInvitationsCollectionName(guestbookId);

  const responseCollection = collection(db, collectionName).withConverter(
    invitationConverter
  );
  const [invitations, invitationsLoading, invitationsError] =
    useCollectionData(responseCollection);

  const getStatus = (status) => {
    let icon = <PendingIcon fontSize="small" color="disabled" />;
    // "PENDING" | "PROCESSING" | "SUCCESS" | "ERROR"
    switch (status) {
      case "ERROR": {
        icon = <SmsFailedIcon fontSize="small" color="error" />;
        break;
      }
      case "PROCESSING": {
        icon = <SendIcon fontSize="small" color="info" />;
        break;
      }
      case "SUCCESS": {
        icon = <CheckCircleIcon fontSize="small" color="success" />;
        break;
      }
      default:
        break;
    }
    return (
      <Tooltip title={status}>
        <IconButton>{icon}</IconButton>
      </Tooltip>
    );
  };

  if (loading || invitationsLoading || !invitations?.length || invitationsError)
    return null;

  return (
    <>
      <MKTypography>Sent Invites:</MKTypography>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          size="small"
          sx={{ minWidth: 325, maxWidth: 450 }}
          aria-label="Sent Invitations"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Contact</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((contact, i) => (
              <TableRow
                key={`${contact.name}-${i}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {contact.name}
                </TableCell>
                <TableCell align="left">
                  {contact.email || contact.phone}
                </TableCell>
                <TableCell align="right">{getStatus(contact.state)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

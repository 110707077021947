import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// @mui material components
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import MoveDownIcon from "@mui/icons-material/MoveDownTwoTone";
import MoveUpIcon from "@mui/icons-material/MoveUpTwoTone";
import DeleteForeverTwoTone from "@mui/icons-material/DeleteForeverTwoTone";

export const AddPromptCard = styled(Card)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#fff" : theme.palette.primary.light,
  borderRadius: "2.5rem",
  color: theme.palette.mode === "dark" ? theme.palette.primary.main : "#fff",
  padding: theme.spacing(1),
}));

AddPromptCard.muiName = Card.muiName;

const PromptCard = styled(Card)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#fff" : theme.palette.black.main,
  borderRadius: "2.5rem",
  color: theme.palette.mode === "dark" ? theme.palette.black.main : "#fff",
  padding: theme.spacing(1),
}));

PromptCard.muiName = Card.muiName;

const PromptCardContent = styled(CardContent)(({ theme }) => ({
  cursor: "pointer",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  ":last-child": {
    paddingBottom: theme.spacing(2),
  },
}));

PromptCardContent.muiName = CardContent.muiName;

const PromptCardActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

PromptCardActions.muiName = CardActions.muiName;

const ActionIconButton = styled(IconButton)(() => ({
  display: "flex",
  flexDirection: "column",
}));

ActionIconButton.muiName = IconButton.muiName;

const ActionLabel = styled("label")(({ theme }) => ({
  fontSize: theme.typography.size.xxs,
}));

export default function Prompt({
  text,
  index,
  isActive,
  onSelect,
  onMove,
  onDelete,
  ...props
}) {
  return (
    <PromptCard
      onClick={() => onSelect()}
      {...props}
      sx={{
        my: 1,
        mx: "auto",
      }}
    >
      <PromptCardContent
        sx={{
          textAlign: "center",
        }}
      >
        <Typography noWrap={!isActive}>{text}</Typography>
      </PromptCardContent>
      {isActive && (
        <PromptCardActions>
          <ActionIconButton
            color="white"
            onClick={() => onMove(index, index + 1)}
          >
            <MoveDownIcon />
            <ActionLabel>Move Down</ActionLabel>
          </ActionIconButton>
          <ActionIconButton color="white" onClick={() => onDelete()}>
            <DeleteForeverTwoTone />
            <ActionLabel>Delete</ActionLabel>
          </ActionIconButton>
          <ActionIconButton
            color="white"
            onClick={() => onMove(index, index - 1)}
          >
            <MoveUpIcon />
            <ActionLabel>Move Up</ActionLabel>
          </ActionIconButton>
        </PromptCardActions>
      )}
    </PromptCard>
  );
}

Prompt.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onMove: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

import * as React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MKTypography from "components/MKTypography";

export default function NewInvitesTable({ data, onDelete }) {
  if (!data || !data?.length) return null;
  return (
    <>
      <MKTypography>Invites to be sent:</MKTypography>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          size="small"
          sx={{ minWidth: 325, maxWidth: 450 }}
          aria-label="New Invitations"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Contact</TableCell>
              <TableCell align="right">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((contact, i) => (
                <TableRow
                  key={`${contact.name}-${i}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {contact.name}
                  </TableCell>
                  <TableCell align="left">
                    {contact.email || contact.phone}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() => onDelete(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
NewInvitesTable.propTypes = {
  data: PropTypes.any,
  onDelete: PropTypes.func,
};

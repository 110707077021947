import React, {
  useReducer,
  useContext,
  createContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import {
  collection,
  serverTimestamp,
  doc,
  setDoc,
  arrayUnion,
  increment,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../firebase";

import { SAVING } from "contexts/shared/constants";
import {
  SET_DATA,
  COMPLETE,
  FILE_UPLOADING,
  FILE_UPLOADED,
  SET_FILES,
} from "./constants";
import { defaultFinalVideoState, reducer } from "./store";
import { useMultiFileUpload } from "hooks/useFileUpload";

export const FinalVideoContext = createContext(defaultFinalVideoState);

export const FinalVideoContextProvider = ({ children, guestbookId, data }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultFinalVideoState,
    guestbookId,
    data: data || defaultFinalVideoState.data,
  });

  return (
    <FinalVideoContext.Provider value={[state, dispatch]}>
      {children}
    </FinalVideoContext.Provider>
  );
};

FinalVideoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  guestbookId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export const useFinalVideoContext = () => {
  const auth = getAuth();
  const [state, dispatch] = useContext(FinalVideoContext);
  const { guestbookId, data, files, uploads, saving, progress } = state;

  const setProgress = useCallback(
    (value) => {
      dispatch({ type: SAVING, payload: value });
    },
    [dispatch]
  );

  const onComplete = React.useCallback(
    (url, index) => {
      dispatch({ type: FILE_UPLOADED, payload: { index, url } });
    },
    [dispatch]
  );

  const onStart = React.useCallback(
    (index) => {
      dispatch({
        type: FILE_UPLOADING,
        payload: { index },
      });
    },
    [dispatch]
  );

  const fileUploader = useMultiFileUpload();
  fileUploader
    .on("start", (_task, index) => onStart(index))
    .on("progress", (progress) => setProgress(progress - 2))
    .on("complete", (url, index) => onComplete(url, index))
    .on("error", (error, index) => {
      console.log("Upload error", error, index);
    });

  if (!FinalVideoContext) {
    throw new Error(
      "useFinalVideoContext should be used inside FinalVideoContextProvider"
    );
  }

  const setData = useCallback(
    (data) => {
      dispatch({ type: SET_DATA, payload: data });
    },
    [dispatch]
  );

  const addFiles = React.useCallback(
    (items) => {
      const uploads = [...items].map((file) => {
        const pathRoot = `guestbook/${guestbookId}/assets`;
        return { pathRoot, file, name: file.name };
      });
      const next = files ? [...files] : [];
      next.push(...uploads);
      dispatch({ type: SET_FILES, payload: next });
    },
    [files, dispatch]
  );

  const saveFiles = async () => {
    if (files && files.length) {
      const uploads = [...files];

      return new Promise((resolve, reject) => {
        const success = (results) => {
          console.log("Uploaded", results);
          resolve(results);
        };
        const failure = (errors) => {
          console.error("Upload failed.", errors);
          reject(errors);
        };
        return fileUploader.upload(uploads, success, failure);
      });
    }
  };

  const saveRequest = useCallback(
    async (assets) => {
      const finalVideoRequestDoc = doc(
        collection(db, `finalVideoRequests`),
        guestbookId
      );
      return await setDoc(finalVideoRequestDoc, {
        id: finalVideoRequestDoc.id,
        uid: auth.currentUser.uid,
        email: auth.currentUser.email,
        ...data,
        assets,
        status: "requested",
        revisions: 0,
        created: serverTimestamp(),
      })
        .then(() => {
          return dispatch({ type: SAVING, payload: 98 });
        })
        .catch((error) => console.log("errored", error));
    },
    [data]
  );

  const updateRequest = useCallback(
    async (assets) => {
      const finalVideoRequestDoc = doc(
        collection(db, `finalVideoRequests`),
        guestbookId
      );
      return await setDoc(
        finalVideoRequestDoc,
        {
          id: finalVideoRequestDoc.id,
          uid: auth.currentUser.uid,
          ...data,
          assets: arrayUnion(...assets),
          updated: serverTimestamp(),
          status: "revise",
          revisions: increment(1),
        },
        { merge: true }
      )
        .then(() => {
          return dispatch({ type: SAVING, payload: 98 });
        })
        .catch((error) => console.log("errored", error));
    },
    [data]
  );

  const updateGuestbookStatus = useCallback(
    async (status = "requested") => {
      const guestbookDoc = doc(collection(db, `guestbook`), guestbookId);
      return await setDoc(
        guestbookDoc,
        {
          finalVideo: { status: status, requested: serverTimestamp() },
        },
        { merge: true }
      )
        .then(() => {
          return dispatch({ type: SAVING, payload: 99 });
        })
        .catch((error) => console.log("errored", error));
    },
    [data]
  );

  const finalize = useCallback(async () => {
    dispatch({ type: SAVING, payload: 0 });
    let assets = [];
    await saveFiles().then((results) => {
      if (results) {
        assets = results;
      }
      return results;
    });

    await saveRequest(assets);

    return await updateGuestbookStatus()
      .then(() => {
        return dispatch({
          type: COMPLETE,
          payload: {},
        });
      })
      .catch((error) => console.log("errored", error));
  }, [dispatch, data, files, auth]);

  const saveRevision = useCallback(async () => {
    dispatch({ type: SAVING, payload: 0 });
    let assets = [];
    await saveFiles().then((results) => {
      if (results) {
        assets = results;
      }
      return results;
    });

    await updateRequest(assets);

    return await updateGuestbookStatus("revise")
      .then(() => {
        return dispatch({
          type: COMPLETE,
          payload: {},
        });
      })
      .catch((error) => console.log("errored", error));
  }, [dispatch, data, files, auth]);

  return {
    setData,
    addFiles,
    save: finalize,
    update: saveRevision,
    files,
    data,
    uploads,
    saving,
    progress,
  };
};

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const FileUploadProps = {
  accept: PropTypes.string.isRequired,
  hoverLabel: PropTypes.string,
  dropLabel: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

const useStyle = makeStyles({
  root: {
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    "&:hover p,&:hover svg,& img": {
      opacity: 1,
    },
    "& p, svg": {
      opacity: 0.4,
    },
    "&:hover img": {
      opacity: 0.3,
    },
  },
  noMouseEvent: {
    pointerEvents: "none",
  },
  iconText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  onDragOver: {
    "& img": {
      opacity: 0.3,
    },
    "& p, svg": {
      opacity: 1,
    },
  },
});

export const FileUpload = ({
  accept,
  hoverLabel = "Click or drag to upload file",
  dropLabel = "Drop file here",
  width = "600px",
  height = "100px",
  backgroundColor = "#fff",
  onChange,
  onDrop,
}) => {
  const classes = useStyle();
  const [labelText, setLabelText] = React.useState(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [, setIsMouseOver] = React.useState(false);
  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      onDrop(e);
    },
  };

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Box sx={{ minHeight: height }}>
      <input
        onChange={handleChange}
        accept={accept}
        className={classes.hidden}
        id="file-upload"
        type="file"
        multiple
      />

      <label
        htmlFor="file-upload"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dragEvents}
        className={clsx(classes.root, isDragOver && classes.onDragOver)}
      >
        <Box
          height={height}
          bgcolor={backgroundColor}
          className={classes.noMouseEvent}
          sx={{
            maxWidth: width,
            flexGrow: 1,
          }}
        >
          <Box height={height} className={classes.iconText}>
            <CloudUploadIcon fontSize="large" />
            <Typography>{labelText}</Typography>
          </Box>
        </Box>
      </label>
    </Box>
  );
};

FileUpload.propTypes = FileUploadProps;

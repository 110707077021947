import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const ResponseButtonBase = styled(Button)(({ theme, color, variant }) => {
  const { palette, functions } = theme;
  const { pxToRem } = functions;
  const { white, text, dark } = palette;

  // color value
  let colorValue = white.main;

  if (color === "default" || !palette[color]) {
    colorValue = text.main;
  } else if (color === "white" || color === "light") {
    colorValue = dark.main;
  }

  // color value when button is focused
  let focusedColorValue = white.main;

  if (color === "default") {
    focusedColorValue = text.main;
  } else if (color === "white") {
    focusedColorValue = dark.main;
  } else if (color === "primary" || color === "error" || color === "dark") {
    focusedColorValue = white.main;
  }
  const colors = {
    root: {
      color: colorValue,
    },
    focused: {
      color: focusedColorValue,
    },
    icon: {},
  };

  if (variant === "text" || variant === "outlined") {
    colors.root = {};
    colors.focused = {};
    colors.icon = { fill: palette[color].main };
  }
  console.log({ variant, colors });
  return {
    ...colors.root,
    textTransform: "capitalize",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    paddingTop: pxToRem(20),
    paddingBottom: pxToRem(20),
    width: "80px",
    height: "80px",
    "& .MuiSvgIcon-root": {
      ...colors.icon,
    },
    "&:disabled": {
      ...colors.focused,
    },
  };
});

const ResponseButton = forwardRef(({ label, icon, children, ...rest }, ref) => (
  <ResponseButtonBase {...rest} ref={ref}>
    {icon}
    <Typography color="inherit" variant="caption" mt={1}>
      {label}
    </Typography>
    {children}
  </ResponseButtonBase>
));

// Setting default values for the props of MKBox
ResponseButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default ResponseButton;

import MKBox from "components/MKBox";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { useSetupContext } from "contexts/setup/SetupContextProvider";
import MKTypography from "components/MKTypography";

// Analytics
import analytics from "../../../analytics";

const baseDate = dayjs().toDate();

export default function SelectDate() {
  const { data, setData } = useSetupContext();
  const { endDate } = data;

  const handleDateChanged = (endDate) => {
    analytics.track("setup:date_changed", {
      weeksOut: dayjs(endDate).diff(baseDate, "weeks"),
      monthsOut: dayjs(endDate).diff(baseDate, "months"),
      yearsOut: dayjs(endDate).diff(baseDate, "years"),
    });
    setData({ endDate: endDate.toDate() });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MKBox component="section" py={4} maxWidth="400px">
        <MKTypography>
          When would you like all responses submitted by?
        </MKTypography>
        <MKTypography variant="subtitle2">
          We recommend selecting a date 3-5 days after your wedding for best
          results.
        </MKTypography>
        <DateCalendar
          sx={{ margin: 0 }}
          value={dayjs(endDate || baseDate)}
          onChange={handleDateChanged}
        />
      </MKBox>
    </LocalizationProvider>
  );
}

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Navigate, Outlet } from "react-router-dom";

import { useAuthContext } from "contexts/AuthContextProvider";

const ProtectedRouteBase = ({ user, redirectPath = "/sign-in" }) => {
  return user && !user.isAnonymous ? (
    <Outlet />
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

const ProtectedRoutes = ({ redirectPath = "/sign-in" }) => {
  const { user } = useAuthContext();
  return <ProtectedRouteBase user={user} redirectPath={redirectPath} />;
};

// Typechecking props for the BasicLayout
ProtectedRouteBase.propTypes = {
  user: PropTypes.any,
  redirectPath: PropTypes.string,
};

ProtectedRoutes.propTypes = {
  redirectPath: PropTypes.string,
};

export default ProtectedRoutes;

import * as React from "react";
import PropTypes from "prop-types";

import { Alert, IconButton } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import GridItem from "components/Grid/GridItem";
import ActionButton from "components/ActionButton";
import { useNavigate } from "react-router";

import useDownloadURL from "hooks/useDownloadURL";

function FinalVideoAlert({ status }) {
  const [showFinalNotification, setShowFinalNotification] =
    React.useState(true);

  return (
    <React.Fragment>
      {["requested", "revise", "review"].includes(status) &&
        showFinalNotification && (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowFinalNotification(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {status === "review"
              ? `Hooray! Your final video is ready for review. You can either
                  accept it in it’s current form, or request any necessary
                  revisions.`
              : `Your ${status === "requested" ? "final video" : "revision"}
                  order has been placed! You’ll be notified as soon as it’s
                  ready to watch.`}
          </Alert>
        )}
    </React.Fragment>
  );
}

FinalVideoAlert.propTypes = {
  status: PropTypes.oneOf(["requested", "revise", "review", "final"])
    .isRequired,
};

function FinalVideoCard({ video }) {
  const [loading, url, error] = useDownloadURL(video.fileName);
  return (
    <Card>
      {loading ? (
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {error
              ? "There was an error loading your video."
              : "Loading your final video..."}
          </Typography>
        </CardContent>
      ) : (
        <CardMedia
          component="video"
          height="208"
          autoPlay
          controls
          src={url}
          alt="Your Final Video"
        />
      )}
    </Card>
  );
}
FinalVideoCard.propTypes = {
  video: PropTypes.any,
};

function FinalVideoReview({ onApproveFinal, video }) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <FinalVideoCard video={video} />
      <Grid container spacing={2}>
        <GridItem item xs={2}></GridItem>
        <GridItem item xs={4}>
          <ActionButton
            label="Accept Final Video"
            icon={<CheckCircleIcon color="white" />}
            variant="contained"
            size="large"
            color="success"
            onClick={onApproveFinal}
          />
        </GridItem>
        <GridItem item xs={4}>
          <ActionButton
            label="Request Revisions"
            icon={<CancelIcon color="white" />}
            variant="contained"
            size="large"
            color="error"
            onClick={() => {
              navigate("revision-request");
            }}
          />
        </GridItem>
        <GridItem item xs={2}></GridItem>
      </Grid>
    </React.Fragment>
  );
}

FinalVideoReview.propTypes = {
  onApproveFinal: PropTypes.func.isRequired,
  video: PropTypes.any,
};

export default function FinalVideoDetail({ status, video, onApproveFinal }) {
  return (
    <React.Fragment>
      <FinalVideoAlert status={status} />
      {status === "review" && (
        <FinalVideoReview video={video} onApproveFinal={onApproveFinal} />
      )}
      {status === "final" && <FinalVideoCard video={video} />}
    </React.Fragment>
  );
}

FinalVideoDetail.propTypes = {
  status: PropTypes.oneOf(["requested", "revise", "review", "final"])
    .isRequired,
  requested: PropTypes.any,
  video: PropTypes.any,
  onApproveFinal: PropTypes.func.isRequired,
};

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import QRCodeStyling from "qr-code-styling";
import qrcodeoptions from "./options";
import MKButton from "components/MKButton";

const qrCode = new QRCodeStyling({ ...qrcodeoptions, width: 150, height: 150 });

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function QRCodeDownload({ url, onDownload }) {
  const [fileExt, setFileExt] = React.useState("png");
  const [preview, setPreview] = React.useState();

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  React.useEffect(() => {
    qrCode.update({
      data: url,
    });

    qrCode.getRawData("svg").then((blob) => {
      setPreview((previous) => {
        if (previous) {
          URL.revokeObjectURL(previous);
        }
        return URL.createObjectURL(blob);
      });
    });
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [url]);

  const onDownloadClick = React.useCallback(() => {
    new QRCodeStyling({ ...qrcodeoptions, data: url })
      .download({
        extension: fileExt,
      })
      .then(() => {
        onDownload && onDownload();
      });
  }, [fileExt]);

  return (
    <Box sx={modalStyle}>
      <Card sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5" id="qr-code-title">
              Your QR Code
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="fileExt-label">Age</InputLabel>
              <Select
                labelId="fileExt-label"
                id="fileExt"
                value={fileExt}
                label="Change file type"
                onChange={onExtensionChange}
              >
                <MenuItem value="jpeg">JPEG</MenuItem>
                <MenuItem value="png">PNG</MenuItem>
                <MenuItem value="webp">WEBP</MenuItem>
                <MenuItem value="svg">SVG</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <MKButton
              color="primary"
              variant="contained"
              endIcon={<FileDownloadIcon sx={{ height: 38, width: 38 }} />}
              aria-label="Download"
              onClick={onDownloadClick}
            >
              Download
            </MKButton>
          </Box>
        </Box>
        <CardMedia
          component="img"
          sx={{ objectFit: "contain", m: 0 }}
          image={preview}
          alt="Your QR Code"
        />
      </Card>
    </Box>
  );
}

QRCodeDownload.propTypes = {
  url: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Layout
import BasicLayout from "layouts/authentication/Basic";

// Components/Hooks/Contexts
import { useAuthContext } from "contexts/AuthContextProvider";

import mapAuthErrorCodeToMessage from "utils/Authentication/mapAuthErrorCodeToMessage";
import useEmailLinkSignIn from "hooks/useEmailLinkSignIn";

// Form Validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

function EmailSignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { error: signinError } = useEmailLinkSignIn();
  const [email, setEmail] = useState(
    window.localStorage.getItem("emailForSignIn")
  );
  const [error, setError] = useState();
  const [signingIn, setSigningIn] = useState(false);
  const { user, logInWithEmail } = useAuthContext();
  const navigate = useNavigate();

  const handleSignIn = ({ email }) => {
    setError(null);
    logInWithEmail(email)
      .then(() => {
        setSigningIn(true);
        setEmail(email);
      })
      .catch((error) => {
        setError(mapAuthErrorCodeToMessage(error.code));
      });
  };

  useEffect(() => {
    if (signinError) setError(mapAuthErrorCodeToMessage(signinError.code));
  }, [signinError]);

  useEffect(() => {
    if (!!user && !user.isAnonymous) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    setValue("email", window.localStorage.getItem("emailForSignIn"));
  }, []);

  return (
    <BasicLayout>
      <Card variant="primary">
        <MKBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              {signingIn ? (
                <MKTypography>
                  An email was sent to {email}. Please use the link in the email
                  to continue.
                </MKTypography>
              ) : (
                <MKInput
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  disabled={signingIn}
                  required
                  {...register("email")}
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email.message : null}
                />
              )}
            </MKBox>
            {error && (
              <MKBox mb={2}>
                <MKAlert color="error" dismissible>
                  <MKBox>
                    <MKTypography variant="h4" color="white" mb={1}>
                      Oh No!
                    </MKTypography>
                    <MKTypography variant="body2" color="white">
                      {error}
                    </MKTypography>
                  </MKBox>
                </MKAlert>
              </MKBox>
            )}
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={handleSubmit(handleSignIn)}
                disabled={signingIn}
              >
                sign in
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default EmailSignIn;

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import EditIcon from "@mui/icons-material/Edit";
import { DatePickerToolbar } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@mui/material";

function CustomToolbar(props) {
  const { onEdit, isOpen } = props;
  return (
    <Box
      // Pass the className to the root element to get correct layout
      className={props.className}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <DatePickerToolbar {...props} />
      <IconButton
        sx={{ m: 2 }}
        disabled={isOpen}
        onClick={() => {
          console.log("Edit", isOpen, onEdit);
          onEdit();
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}
CustomToolbar.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default function DatePicker({ onAccept, value }) {
  const defaultValue = (value && dayjs(value)) || dayjs().add(90, "days");
  const [isOpen, setIsOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(defaultValue);

  const onAccepted = (date) => {
    setCurrent(date);
    setIsOpen(false);
    onAccept(current.toDate());
  };

  const onCancel = () => {
    setCurrent(defaultValue);
    setIsOpen(false);
  };

  const min = dayjs().add(7, "day");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        disablePast
        minDate={min}
        value={current}
        onAccept={onAccepted}
        onChange={(date, context) => {
          console.log(date, context);
          setCurrent(date);
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          layout: {
            sx: {
              [`.${pickersLayoutClasses.contentWrapper},.${pickersLayoutClasses.actionBar}`]:
                {
                  display: isOpen ? "flex" : "none",
                },
            },
          },
          actionBar: {
            onCancel,
          },
          toolbar: {
            isOpen,
            onEdit: () => {
              setIsOpen((prev) => !prev);
            },
            hidden: false,
            toolbarFormat: dayjs().isSame(current, "year")
              ? "ddd, MMM D"
              : "ddd, MMM D YYYY",
          },
        }}
      />
    </LocalizationProvider>
  );
}
DatePicker.propTypes = {
  className: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

import * as React from "react";

import Typography from "@mui/material/Typography";
import Setup from "./components";
import { SetupContextProvider } from "contexts/setup/SetupContextProvider";
import steps from "./components/Steps";

export default function SetupPage() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
        Setup
      </Typography>
      <SetupContextProvider steps={steps}>
        <Setup />
      </SetupContextProvider>
    </React.Fragment>
  );
}

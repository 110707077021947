/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";
import { FileUpload } from "components/FileUpload";
import { FormHelperText, LinearProgress } from "@mui/material";

// Form Validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Analytics
import analytics from "../../../analytics";
import getResizedImageUrls from "utils/Storage/getResizedImageUrls";
import { getResizedImageUrl } from "utils/Storage/getResizedImageUrls";

const validationSchema = Yup.object().shape({
  message: Yup.string().required(
    'A message is required. A simple "Congratulations!" will do.'
  ),
  signature: Yup.string().required("Please add your signature."),
});

const Item = styled(Box)(() => ({
  textAlign: "center",
}));

export default function SignGuestbook({ onSigned }) {
  const { loading, guestbook, sign, progress } = useGuestbookContext();
  const [file, setFile] = React.useState();
  const [preview, setPreview] = React.useState();
  const [photo, setPhoto] = React.useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const clearPreview = (url) => {
    URL.revokeObjectURL(url);
    return undefined;
  };

  React.useEffect(() => {
    if (guestbook) {
      getResizedImageUrl(guestbook.imageFile, "600x600")
        .then((results) => {
          setPhoto(results);
        })
        .catch(() => setPhoto(guestbook.image));
    }
  }, [guestbook]);

  React.useEffect(() => {
    if (!file) {
      setPreview((previous) => clearPreview(previous));
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => clearPreview(objectUrl);
  }, [file]);

  const handleSignGuestbook = ({ message, signature }) => {
    analytics.track("guestbook:sign_guestbook_click", {
      includedPhoto: !!file,
    });
    sign({ message, signature, file }).then(() => {
      onSigned && onSigned();
    });
  };

  const fileUploadProp = {
    accept: "image/*",
    onChange: (event) => {
      analytics.track("guestbook:photo_changed");
      if (event.target.files !== null && event.target?.files?.length > 0) {
        setFile(event.target.files[0]);
      }
    },
    onDrop: (event) => {
      if (
        event.dataTransfer.files !== null &&
        event.dataTransfer?.files?.length > 0
      ) {
        analytics.track("guestbook:photo_dropped");
        const files = [...event.dataTransfer.files].filter((file) => {
          return file.type.split("/").shift() === "image";
        });
        if (files?.length > 0) {
          setFile(files[0]);
        }
      }
    },
  };

  return (
    <Stack spacing={2}>
      <Item sx={{ mt: 2 }}>
        {loading ? (
          <Skeleton />
        ) : (
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ p: 2, pb: 0 }}
          >
            {guestbook && guestbook.toString()}
          </Typography>
        )}
      </Item>
      <Item>
        {loading || !photo ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <img
            src={`${photo}`}
            alt={guestbook.title}
            loading="lazy"
            width="100%"
          />
        )}
      </Item>
      <Item px={2}>
        {loading ? (
          <Skeleton />
        ) : (
          <Typography variant="subtitle1" gutterBottom>
            {guestbook && guestbook.message}
          </Typography>
        )}
      </Item>
      <Item px={2}>
        <MKTypography variant="subtitle2" gutterBottom>
          Start by adding your signature to our guestbook. Then, you can add a
          couple of video, photo, or text responses on the next page.
        </MKTypography>
      </Item>
      <Item px={2}>
        <TextField
          id="message"
          name="message"
          label="Message for the couple"
          multiline
          maxRows={10}
          minRows={4}
          fullWidth
          required
          {...register("message")}
          error={errors.message ? true : false}
          helperText={errors.message ? errors.message.message : null}
          onFocus={() => analytics.track("guestbook:message_focus")}
          onBlur={() => analytics.track("guestbook:message_blur")}
        />
      </Item>
      <Item px={2}>
        <FormControl fullWidth required error={errors.signature ? true : false}>
          <InputLabel htmlFor="signature">Signature</InputLabel>
          <OutlinedInput
            id="signature"
            name="signature"
            label="Signature"
            required
            {...register("signature")}
            onFocus={() => analytics.track("guestbook:signature_focus")}
            onBlur={() => analytics.track("guestbook:signature_blur")}
          />
          <FormHelperText>
            {errors.signature ? errors.signature.message : null}
          </FormHelperText>
        </FormControl>
      </Item>
      <Item px={2}>
        <MKTypography variant="body2" gutterBottom>
          Add a Photo to Your Signature (Optional)
        </MKTypography>
        <Card>
          {preview && (
            <CardMedia
              component="img"
              alt="Selected File Preview"
              image={
                preview ||
                "https://fakeimg.pl/300x200/cccccc/fff?text=+optional&font=noto"
              }
            />
          )}
          <CardContent>
            <FileUpload {...fileUploadProp} />
          </CardContent>
        </Card>
      </Item>

      {progress > 0 && (
        <Item>
          <LinearProgress variant="determinate" value={progress} />
        </Item>
      )}
      <Item>
        <MKButton color="dark" onClick={handleSubmit(handleSignGuestbook)}>
          Sign Our Guestbook
        </MKButton>
      </Item>
    </Stack>
  );
}

SignGuestbook.propTypes = {
  onSigned: PropTypes.func,
};

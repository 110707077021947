import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ImageCropper from "./ImageCropper";

export default function ImageCropperPopup({
  open,
  src,
  handleClose,
  getCroppedData,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Crop Image</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ImageCropper
              handleClose={handleClose}
              src={src}
              getCroppedData={getCroppedData}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
ImageCropperPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  getCroppedData: PropTypes.func.isRequired,
};

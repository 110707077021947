import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";

import MKProgress from "components/MKProgress";
import ResponseButton from "../ResponseButton";

import { useGuestbookContext } from "contexts/guestbook/GuestbookContextProvider";

import defaultImage from "assets/images/image_placeholder.jpg";

export default function PhotoResponse({
  file,
  previousValue,
  onCancel,
  onSave,
}) {
  const { saving, progress } = useGuestbookContext();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    (previousValue && previousValue.url) || defaultImage
  );

  const handleSave = () => {
    onSave && onSave({ content: file, responseType: "photo" });
  };

  const handleFileChange = useCallback(() => {
    if (!file) return;
    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    handleFileChange();
  }, [file]);

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={imagePreviewUrl}
          style={{ maxWidth: "100%", maxHeight: "50vh" }}
        />
      </Box>

      {saving && <MKProgress value={progress} color="primary" />}
      <Box>
        <Stack direction="row">
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <ResponseButton
              color="error"
              icon={<CancelIcon />}
              label="Delete"
              variant="text"
              size="large"
              onClick={onCancel}
              disabled={saving}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {!previousValue && (
              <ResponseButton
                color="success"
                icon={<CheckIcon />}
                label="Save"
                variant="text"
                size="large"
                onClick={handleSave}
                disabled={!file || saving}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

PhotoResponse.propTypes = {
  file: PropTypes.object,
  previousValue: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

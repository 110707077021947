import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";

const storage = getStorage();

const getAllFiles = (folderRef) => {
  return listAll(folderRef).then((res) => {
    return res.items.map((item) => {
      getMetadata(item).then((data) => console.info(data));
      return getDownloadURL(item);
    });
  });
};

export function getResizedImageUrl({ filename, path }, size) {
  const re = /(?:\.([^.]+))?$/;
  const ext = re.exec(filename)[1];
  const file = `${filename.replace(/(\.[^/.]+)+$/, "")}_${size}.${ext}`;
  const fullPath = `${path}/scaled/${file}`;
  console.log("Get Resized File:", fullPath);
  const fileRef = ref(storage, fullPath);
  return getDownloadURL(fileRef);
}

export default function getResizedImageUrls(guestbookId) {
  console.log(
    "Get all resized images in:",
    `guestbook/${guestbookId}/image/scaled`
  );
  const listRef = ref(storage, `guestbook/${guestbookId}/image/scaled`);
  return getAllFiles(listRef).then((promises) => {
    return Promise.all(promises);
  });
}

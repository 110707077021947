import React from "react";
import {
  useReducer,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { defaultGuestbookState, reducer } from "./store";
import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";
import signatureConverter from "../../firebase/converters/signature";
import responseConverter from "../../firebase/converters/response";
import { useAuthContext } from "contexts/AuthContextProvider";
import {
  SET_CURRENT_STEP,
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SAVING,
} from "contexts/shared/constants";
import {
  REMOVE_RESPONSE,
  SAVE_RESPONSE,
  SET_GUESTBOOK,
  SET_LOADING,
  SET_RESPONSES,
  SIGN_GUESTBOOK,
} from "./constants";
import useFileUpload from "hooks/useFileUpload";

export const GuestbookContext = createContext(defaultGuestbookState);

export const GuestbookContextProvider = ({ children }) => {
  const { slug } = useParams();
  const [state, dispatch] = useReducer(reducer, { ...defaultGuestbookState });
  const auth = useAuthContext();
  const getGuestbook = async () => {
    let ref = collection(db, "guestbook");
    const q = query(ref, where("slug", "==", slug), limit(1)).withConverter(
      guestbookConverter
    );
    const querySnapshot = await getDocs(q);
    const doc = querySnapshot.docs.pop();
    if (doc.exists()) {
      dispatch({ type: SET_GUESTBOOK, payload: doc.data() });
    }
    return doc.data();
  };

  const getSignature = async (guestbook) => {
    if (!guestbook) return;
    let ref = collection(db, `guestbook/${guestbook.id}/signatures`);
    const q = query(
      ref,
      where("uid", "==", auth.user.uid),
      limit(1)
    ).withConverter(signatureConverter);
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs.pop();
      if (doc.exists()) {
        dispatch({ type: SIGN_GUESTBOOK, payload: doc.data() });
      }
    }
    return guestbook;
  };

  const getResponses = async (guestbook) => {
    if (!guestbook) return;
    let ref = collection(db, `guestbook/${guestbook.id}/responses`);
    const q = query(ref, where("uid", "==", auth.user.uid)).withConverter(
      responseConverter
    );
    const querySnapshot = await getDocs(q);
    const responses = querySnapshot.docs.map((doc) => {
      if (doc.exists()) {
        return doc.data();
      }
    });
    dispatch({ type: SET_RESPONSES, payload: responses });
    return guestbook;
  };

  useEffect(() => {
    getGuestbook()
      .then(getSignature)
      .then(getResponses)
      .finally(() => {
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch((e) => {
        console.error("There was an error loading the guestbook data.", e);
      });
  }, []);

  return (
    <GuestbookContext.Provider value={[state, dispatch]}>
      {children}
    </GuestbookContext.Provider>
  );
};

GuestbookContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useGuestbookContext = () => {
  const auth = useAuthContext();
  const [state, dispatch] = useContext(GuestbookContext);
  const {
    loading,
    guestbook,
    signed,
    responses,
    saving,
    progress,
    currentStep,
    maxSteps,
  } = state;

  const [error, setError] = React.useState();

  const setProgress = useCallback(
    (value) => {
      dispatch({ type: SAVING, payload: value });
    },
    [dispatch]
  );

  const fileUploader = useFileUpload();
  fileUploader
    .on("start", () => {
      setProgress(1);
    })
    .on("progress", (i) => {
      setProgress(i);
      console.log("Upload progress", i);
    })
    .on("error", (error) => {
      console.log("Upload error", error);
      setError(error.message);
      setProgress(0);
    })
    .on("complete", () => {
      setProgress(100);
    });

  if (!GuestbookContext) {
    throw new Error(
      "useGuestbookContext should be used inside GuestbookContextProvider"
    );
  }

  const saveFile = (file, path, keyId) => {
    return new Promise((resolve, reject) => {
      const filename = `${keyId}.${file.name.split(".").pop()}`;
      console.log(filename, path);
      const onSuccess = (url) => resolve({ url, filename, path });
      fileUploader.upload(path, file, filename, onSuccess, reject);
    });
  };

  const signGuestbook = useCallback(
    async ({ message, signature, file }) => {
      const { id } = guestbook;
      const payload = {
        message,
        signature,
        uid: auth.user.uid,
        created: serverTimestamp(),
      };
      dispatch({ type: SAVING, payload: 0 });
      const refSig = doc(collection(db, `guestbook/${id}/signatures`));
      if (file) {
        const result = await saveFile(
          file,
          `guestbook/${id}/signatures`,
          refSig.id
        );
        payload.content = result;
        dispatch({ type: SAVING, payload: 99 });
      }
      return setDoc(refSig, payload).then(() => {
        return dispatch({
          type: SIGN_GUESTBOOK,
          payload: { id: refSig.id, ...payload },
        });
      });
    },
    [dispatch, auth]
  );

  const saveContactInfo = useCallback(
    async ({ contact, method }) => {
      const { id } = guestbook;
      const signatureId = signed.id;
      const payload = {
        contact,
        method,
        uid: auth.user.uid,
        updated: serverTimestamp(),
        sendReminders: true,
      };
      dispatch({ type: SAVING, payload: 50 });
      const signature = doc(
        collection(db, `guestbook/${id}/signatures`),
        signatureId
      );
      return setDoc(signature, payload, { merge: true }).then(() => {
        return dispatch({
          type: SIGN_GUESTBOOK,
          payload: { ...signed, ...payload },
        });
      });
    },
    [dispatch, auth]
  );

  const saveResponse = useCallback(
    async ({ promptId, content, responseType = "text" }) => {
      dispatch({ type: SAVING, payload: 1 });
      const { id } = guestbook;
      const signatureId = signed.id;
      const payload = {
        signatureId,
        promptId,
        content,
        responseType,
        uid: auth.user.uid,
        created: serverTimestamp(),
      };

      switch (responseType) {
        case "photo":
        case "video": {
          const response = doc(collection(db, `guestbook/${id}/responses`));
          const result = await saveFile(
            content,
            `guestbook/${id}/responses`,
            response.id
          );
          payload.content = result;
          dispatch({ type: SAVING, payload: 99 });
          return setDoc(response, payload).then(() => {
            return dispatch({
              type: SAVE_RESPONSE,
              payload: { id: response.id, ...payload },
            });
          });
        }
        case "text":
          dispatch({ type: SAVING, payload: 50 });
          return addDoc(
            collection(db, `guestbook/${id}/responses`),
            payload
          ).then((doc) => {
            return dispatch({
              type: SAVE_RESPONSE,
              payload: { id: doc.id, ...payload },
            });
          });
        default:
          break;
      }
    },
    [dispatch, auth]
  );

  const removeResponse = useCallback(
    (response) => {
      const { id } = guestbook;
      const remove = doc(db, `guestbook/${id}/responses`, response.id);
      return deleteDoc(remove).then(() => {
        return dispatch({ type: REMOVE_RESPONSE, payload: response });
      });
    },
    [dispatch]
  );

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const gotoStep = useCallback(
    (stepIndex) => {
      dispatch({ type: SET_CURRENT_STEP, payload: stepIndex });
    },
    [dispatch]
  );

  return {
    loading,
    guestbook,
    signed,
    responses,
    error,
    saving,
    progress,
    currentStep,
    maxSteps,
    sign: signGuestbook,
    remind: saveContactInfo,
    respond: saveResponse,
    remove: removeResponse,
    next: incrementCurrentStep,
    previous: decrementCurrentStep,
    goto: gotoStep,
  };
};

import * as React from "react";
//import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";

import seperator from "assets/images/shapes/heading-seperator.svg";
import Loading from "pages/guestbook/components/Loading";

import PackageCards from "./components/PackageCards";

export default function SilverUpgradePage() {
  //const { user } = useAuthContext();
  const { id } = useParams();
  const ref = doc(db, "guestbook", id).withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(ref);

  if (loading && !guestbook) return <Loading />;
  if (error) return <Alert severity="error">Error</Alert>;
  return (
    <React.Fragment>
      <Paper square sx={{ pt: "2rem" }}>
        <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
          <Stack direction="column" spacing={2} mb={2}>
            <Box>
              <Box textAlign="center">
                <Typography
                  align="center"
                  variant="h2"
                  component="h2"
                  sx={{ p: 2, pb: 0 }}
                >
                  Upgrade Digital Guestbook Package
                </Typography>
                <img src={seperator} />
              </Box>
            </Box>
            <Typography>
              Considering upgrading? Here&apos;s what might be missing from your
              Quilted experience.
            </Typography>
            <Card>
              <CardMedia
                component="iframe"
                image="https://www.youtube.com/embed/AjQzSKJDEpg"
                frameBorder={0}
                sx={{
                  height: 308,
                  border: 0,
                }}
              />
            </Card>
            <Typography variant="body2" gutterBottom>
              You&apos;ve captured the well-wishes of your loved ones, and now
              we invite you to take your guestbook to the next level.
            </Typography>
            <Typography variant="body2" gutterBottom>
              An upgrade to the Gold package allows you to transform the
              heartfelt memories into a professionally edited, downloadable
              montage video.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Want to be in the director&apos;s seat? The Diamond package gives
              you the ability to fully customize your video keepsake by adding
              additional photos / videos, request a reordering of clips, and up
              to two rounds of revision.
            </Typography>
            <PackageCards current={guestbook.tier} show="upgrades" />
          </Stack>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

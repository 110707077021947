import * as React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Container } from "@mui/material";

import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import ShareBox from "components/ShareBox";

export default function Sharing({ slug }) {
  return (
    <React.Fragment>
      <Container fixed>
        <MKTypography variant="h1" sx={{ p: 2, pb: 0, textAlign: "center" }}>
          Cheers!
        </MKTypography>
        <MKTypography variant="h2" sx={{ p: 2, pb: 4, textAlign: "center" }}>
          Your Digital Wedding Guestbook is Live!
        </MKTypography>
        <MKTypography
          variant="h4"
          gutterBottom
          sx={{ p: 2, pb: 4, textAlign: "center" }}
        >
          Start Sharing:
        </MKTypography>
        <MKBox component="section" maxWidth="400px" sx={{ mx: "auto", mb: 4 }}>
          <ShareBox slug={slug} />
          <Stack spacing={4} mt={4}>
            <MKTypography size="sm" sx={{ textAlign: "center" }}>
              You can also grab this link anytime from your Quilted Dashboard,
              as well as send invites to your guests, view videos and photos as
              they come in, and more.
            </MKTypography>

            <MKButton color="dark" component={RouterLink} to="/dashboard">
              Go to Dashboard
            </MKButton>
          </Stack>
        </MKBox>
      </Container>
    </React.Fragment>
  );
}

Sharing.propTypes = {
  slug: PropTypes.string.isRequired,
};

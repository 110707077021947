class Invitation {
  constructor(id, data) {
    this.id = id;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.state = data.state;
    this.title = data.title;
    this.link = data.link;
    this.photo = data.photo;
  }
}

// Firestore data converters
const invitationConverter = {
  toFirestore: (data) => {
    console.log("Converted from IInvitation to Firestore", data);
    return {
      ...data,
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const id = snapshot.id;
    const invitation = new Invitation(id, data);
    return invitation;
  },
};

export default invitationConverter;

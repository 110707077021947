export const SET_STEPS = "SET_STEPS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const INCREMENT_CURRENT_STEP = "INCREMENT_CURRENT_STEP";
export const DECREMENT_CURRENT_STEP = "DECREMENT_CURRENT_STEP";

export const SAVING = "SAVING";

export const DEFAULT_REMINDERS = {
  midway: true,
  oneDayAfter: false,
  dueDate: false,
  oneDayBefore: true,
};

import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Layout
import BasicLayout from "layouts/authentication/Basic";

// Components/Hooks/Contexts
import { useAuthContext } from "contexts/AuthContextProvider";

function SignOut() {
  const { user, logOut } = useAuthContext();
  const navigate = useNavigate();

  const handleSignOut = () => {
    logOut().then(() => {
      console.log("Signed Out.");
      navigate("/sign-in");
    });
  };

  return (
    <BasicLayout>
      <Card variant="primary">
        <MKBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign Out
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKTypography variant="body1" fontWeight="light" color="text">
                {user ? (
                  <>
                    You are signed in as{" "}
                    <MKTypography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {" "}
                      {user.displayName}
                    </MKTypography>
                  </>
                ) : (
                  "You are not signed in"
                )}
              </MKTypography>
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={handleSignOut}
              >
                sign out
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignOut;

import * as React from "react";
import { useHref, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import guestbookConverter from "../../firebase/converters/guestbook";

import useLocalStorage from "hooks/useLocalStorage";

import seperator from "assets/images/shapes/heading-seperator.svg";
import Loading from "pages/guestbook/components/Loading";
import MKTypography from "components/MKTypography";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import DatePicker from "./components/SettingsDatePicker";
import { DEFAULT_REMINDERS } from "contexts/shared/constants";
import {
  DiamondPackage,
  GoldPackage,
  SilverPackage,
} from "./components/PackageCards";

export default function GuestbookSettingssPage() {
  //const { user } = useAuthContext();
  const { id } = useParams();
  const docRef = doc(db, "guestbook", id).withConverter(guestbookConverter);
  const [guestbook, loading, error] = useDocumentData(docRef);
  const [settings, setSettings] = React.useState({
    ...DEFAULT_REMINDERS,
    ...guestbook?.reminders,
  });

  React.useEffect(() => {
    if (!loading && guestbook) {
      setSettings(guestbook.reminders);
    }
  }, [loading, guestbook]);

  const saveReminders = (reminders) => {
    return setDoc(docRef, { ...guestbook, reminders }, { merge: true });
  };

  const saveEndDate = (endDate) => {
    setDoc(docRef, { ...guestbook, endDate }, { merge: true });
  };

  const [showTipOne, setShowTipOne] = useLocalStorage(
    "tip-guestbooksettings-is-open",
    true
  );

  const onOptionChange = (setting, isChecked) => {
    setSettings((prev) => {
      prev[setting] = isChecked;
      saveReminders(prev).then(() => console.log("Saved"));
      return { ...prev };
    });
  };

  const href = useHref("../silver", { relative: "path" });

  if (loading) return <Loading />;
  if (error) return <Alert severity="error">Error</Alert>;
  return (
    <React.Fragment>
      <Paper square sx={{ pt: "2rem" }}>
        <Box sx={{ maxWidth: 400, mx: "auto!important", pb: "1rem" }}>
          <Stack direction="column" spacing={6} mb={2}>
            <Box>
              <Box textAlign="center">
                <Typography
                  align="center"
                  variant="h2"
                  component="h2"
                  sx={{ p: 2, pb: 0 }}
                >
                  Settings
                </Typography>
                <img src={seperator} />
              </Box>
              <Collapse in={showTipOne}>
                <Alert
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowTipOne(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Welcome to your Digital Guestbook Settings! Here, you can
                  choose when reminders should go out to your participants,
                  adjust your due date, & upgrade your experience.
                </Alert>
              </Collapse>
            </Box>
            <Stack direction="column" spacing={2}>
              <MKTypography variant="h6">
                When would you like reminders to be sent?
              </MKTypography>
              <Paper variant="outlined" sx={{ p: "0.75rem" }}>
                <Typography variant="body2" gutterBottom>
                  We will send automated reminders to people who have not
                  responded. Select which you’d like us to send.
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="info"
                        checked={settings.midway}
                        onChange={(e) =>
                          onOptionChange(e.target.value, e.target.checked)
                        }
                      />
                    }
                    label="Midway"
                    value={"midway"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="info"
                        checked={settings.oneDayBefore}
                        onChange={(e) =>
                          onOptionChange(e.target.value, e.target.checked)
                        }
                      />
                    }
                    label="1 day before"
                    value={"oneDayBefore"}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="info"
                        checked={settings.dueDate}
                        onChange={(e) =>
                          onOptionChange(e.target.value, e.target.checked)
                        }
                      />
                    }
                    label="Due date"
                    value={"dueDate"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="info"
                        checked={settings.oneDayAfter}
                        onChange={(e) =>
                          onOptionChange(e.target.value, e.target.checked)
                        }
                      />
                    }
                    label="1 day after"
                    value={"oneDayAfter"}
                  />
                </FormGroup>
              </Paper>
            </Stack>
            <Stack direction="column" spacing={2}>
              <MKTypography variant="h6">
                All responses should be submitted by:
              </MKTypography>
              <DatePicker
                value={new Date(guestbook.endDate)}
                onAccept={(value) => {
                  saveEndDate(value);
                }}
              />
            </Stack>

            <Stack direction="column" spacing={2}>
              <MKTypography variant="h6">
                My Digital Guestbook Package:
              </MKTypography>
              {guestbook.tier === "silver" && (
                <SilverPackage
                  isCurrent
                  action={{
                    type: "internal",
                    route: href,
                    label: "upgrade",
                  }}
                />
              )}
              {guestbook.tier === "gold" && (
                <GoldPackage isCurrent includeAllSpecs />
              )}
              {guestbook.tier === "diamond" && (
                <DiamondPackage isCurrent includeAllSpecs />
              )}
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
